import React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import addUploadFeature from './addUploadFeature';
import restaurantMedia from './restaurantMedia';
import Menu from './Menu';
import FolderIcon from '@material-ui/icons/Folder';
import PhotoIcon from '@material-ui/icons/Photo';
import customRoutes from './customRoutes';
import Dashboard from './Dashboard';
import domainZhTWMessages from './i18n/zhTW.js';
import domainEnMessages from './i18n/en.js';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { WechatFolderList, WechatFolderEdit, WechatFolderCreate } from './WechatFolder';
import { WechatRestaurantList, WechatRestaurantEdit, WechatRestaurantCreate } from './WechatRestaurant';
import { TakeawayRefundList, TakeawayRefundShow } from './TakeawayRefund';
import MyLayout from './MyLayout';
import LoginPage from './LoginPage';

const messages = {
    'zh-TW': domainZhTWMessages,
    en: domainEnMessages,
};

const i18nProvider = locale => messages[locale];
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const App = () => (
    <Admin loginPage={LoginPage} appLayout={MyLayout} locale="zh-TW" i18nProvider={i18nProvider} dashboard={Dashboard} menu={Menu} customRoutes={customRoutes} authProvider={authProvider} dataProvider={uploadCapableDataProvider} >
        {/* {permissions => [
            // Restrict access to the edit and remove views to admin only
            // Only include the following resource for ROLE_SYSTEM_ADMIN users
            (permissions === 'ROLE_SYSTEM_ADMIN') && <Resource name="folder" list={FolderList} edit={FolderEdit} create={FolderCreate} />,
        ]} */}
        {/* <Resource name="restaurant" icon={LabelIcon} /> */}
        <Resource name="wechat/folder" list={WechatFolderList} edit={WechatFolderEdit} create={WechatFolderCreate} icon={FolderIcon} />        
        <Resource name="wechat/restaurant" list={WechatRestaurantList} edit={WechatRestaurantEdit} create={WechatRestaurantCreate} icon={RestaurantIcon} />
        <Resource name="restaurant/media" {...restaurantMedia} icon={PhotoIcon} />
        <Resource name="refund/takeaway" list={TakeawayRefundList} edit={TakeawayRefundShow} icon={FolderIcon} />        
    </Admin>
);

export default App;