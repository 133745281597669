import React from 'react';
import { Route } from 'react-router-dom';
import Slideshow from './Slideshow';
import SlideshowTemplate from './SlideshowTemplate';
import SlideshowAdv from './SlideshowAdv';
import Configuration from './configuration/Configuration';
import { WithPermissions } from 'react-admin';

const SlideshowWithPermissions = ({ location, match}) => (
    <WithPermissions
        authParams={{ key: match.path }}
        // location is not required but it will trigger a new permissions check if specified when it changes
        location={location}
        render={({ permissions }) => <Slideshow permissions={permissions} /> }
    />
  );

  const SlideshowTemplateWithPermissions = ({ location, match}) => (
    <WithPermissions
        authParams={{ key: match.path }}
        location={location}
        render={({ permissions }) => <SlideshowTemplate permissions={permissions} /> }
    />
  );

const SlideshowAdvWithPermissions = ({ location, match}) => (
    <WithPermissions
        authParams={{ key: match.path }}
        location={location}
        render={({ permissions }) => <SlideshowAdv permissions={permissions} /> }
    />
  );

export default [
    <Route exact path="/slideshow" component={SlideshowWithPermissions} />,
    <Route exact path="/slideshow_template" component={SlideshowTemplateWithPermissions} />,
    <Route exact path="/slideshow_adv" component={SlideshowAdvWithPermissions} />,
    <Route exact path="/configuration" component={Configuration} />,
];