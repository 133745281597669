import React, { Component } from 'react';
import { SaveButton, translate, CREATE, UPDATE } from 'react-admin';
import ConfirmationDialog from './ConfirmationDialog';
import PropTypes from 'prop-types';
import dataProvider from '../dataProvider';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

class CustomButton extends Component {
    constructor(props) {
		super(props);
		
		this.state = {
            isDialogOpen: false,
            error: null,
            record: null,
		};
    }
    
    handleClick = () => {
        const { handleSubmit, resourceName, type, needToSubmit, record, basePath, redirect, push } = this.props;
        
        if(!needToSubmit && record && basePath) {
            let path = "";

            if(typeof redirect === "function"){
                path = redirect(basePath, record.id)
            } else {
                path = redirect;
            }
            
            return () => {
                push(path);

            };
        } else {
            return handleSubmit(values => {
                this.setState({ 
                    isDialogOpen: false,
                    error: null,
                    record: null
                });

                if(type === "CREATE") {
                    dataProvider(CREATE, resourceName, { data: values })
                    .then((response) => {
                        this.setState({ record : response.data });
                    })
                    .catch((error) => {
                        this.setState({ error : JSON.parse(error.message) });
                    })
                    .finally(() => {
                        this.setState({ isDialogOpen: true });
                    });
                } else if(type === "UPDATE") {
                    dataProvider(UPDATE, resourceName, { id: values.id, data: values })
                    .then((response) => {
                        this.setState({ record : response.data });
                    })
                    .catch((error) => {
                        this.setState({ error : JSON.parse(error.message) });
                    })
                    .finally(() => {
                        this.setState({ isDialogOpen: true });
                    });
                }            
            });
        }
    };

    render() {
        const { redirect, label, translate, basePath, push } = this.props;
        const { record, error } = this.state;

        return (
            <div>
                <SaveButton
                    label={label}
                    handleSubmitWithRedirect={this.handleClick}
                />
                <ConfirmationDialog
                    isOpen={this.state.isDialogOpen}
                    title={translate('pos.dialog.title')}
                    content={error ? error.message : translate('pos.dialog.slideshow_success.content')}
                    confirmText={translate('pos.dialog.slideshow_success.confirm')}
                    handleConfirm={()=> {
                        this.setState({ isDialogOpen: false });
                        if(!error && record){
                            let path = "";

                            if(typeof redirect === "function"){
                                path = redirect(basePath, record.id)
                            } else {
                                path = redirect;
                            }
                            
                            push(path);
                        }
                    }}
                />
            </div>
        );
    }
}

CustomButton.propTypes = {
    type: PropTypes.string.isRequired,
    resourceName: PropTypes.string.isRequired,
    needToSubmit: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    push: PropTypes.func,
    redirect: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const enhance = compose(
    connect(
		null,
		{
            push
        }
	),
    translate
);

export default enhance(CustomButton);