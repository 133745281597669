export const errorMessages = {
    TC: {
      emptyInterval: '請填寫幻燈片的間隔',
      invalidInterval: '幻燈片的間隔格式不正確',
      emptyRatio: '請填寫比例',
      invalidRatio: '比例格式不正確',
      emptySlideshowTemplateName: '請填寫名稱',
      invalidDate: '日期格式不正確',
      emptyGuluTemplate: '請選擇模板',
      emptyAdvTemplate: '請選擇模板',
    },
    EN: {
      emptyInterval: 'Please fill in the interval of slideshow',
      invalidInterval: 'Invalid the interval of slideshow',
      emptyRatio: 'Please fill in ratio',
      invalidRatio: 'Invalid ratio format',
      emptySlideshowTemplateName: 'Please fill in name',
      invalidDate: 'Invalid date format',
      emptyGuluTemplate: 'Please select a template',
      emptyAdvTemplate: 'Please select a template',
    },
    SC: {
      emptyInterval: '请填写幻灯片的间隔',
      invalidInterval: '幻灯片的间隔格式不正确',
      emptyRatio: '请填写比例',
      invalidRatio: '比例格式不正确',
      emptySlideshowTemplateName: '请填写名称',
      invalidDate: '日期格式不正确',
      emptyGuluTemplate: '请选择模板',
      emptySovTemptyAdvTemplateemplate: '请选择模板',
    }
  }
  