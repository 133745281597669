import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    switch (type) {
    case GET_LIST: {
        const { field, order } = params.sort;
        let query = {};

        if(params.pagination) {            
            const { page, perPage } = params.pagination;

            query = {
                sort: field,
                order,
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                page: page-1,
                perPage,
                filter: JSON.stringify(params.filter),
                lang: 'EN'
            };
        } else {
            query = {
                sort: field,
                order,
                filter: JSON.stringify(params.filter),
                lang: 'EN'
            };
        }
        
        return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case GET_ONE:
        return { url: `${API_URL}/${resource}/${params.id}` };
    case GET_MANY: {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case GET_MANY_REFERENCE: {
        const { field, order } = params.sort;
        let query = {};

        if(params.pagination) {            
            const { page, perPage } = params.pagination;
            
            query = {
                filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
                sort: field,
                order,
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                page: page-1,
                perPage,
                lang: 'EN',
                restrictSearch: true
            };
        } else{
            query = {
                filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
                sort: field,
                order,
                lang: 'EN',
                restrictSearch: true
            };
        }
        
        return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case UPDATE:
        return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: { method: 'PUT', body: JSON.stringify(params.data) },
        };
    case CREATE:
        return {
            url: `${API_URL}/${resource}`,
            options: { method: 'POST', body: JSON.stringify(params.data) },
        };
    case DELETE:
        return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: { method: 'DELETE' },
        };
    default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { headers, json } = response;
    
    if(json.returnCode !== 0) {
        throw new Error(JSON.stringify(json));
    }

    switch (type) {
        case GET_LIST:
        case GET_MANY_REFERENCE:
            return {
                data: json.payload.map(x => x),
                total: parseInt(headers.get('X-Total-Count').split('/').pop(), 10),
                returnCode: json.returnCode,
                message: json.message
            };
        case GET_ONE:
            return {
                data: json.payload,
                returnCode: json.returnCode,
                message: json.message
            };
        case CREATE:
            // return { data: { ...params.data, id: json.payload.id, mediaUrl: json.payload.mediaUrl } };
            return {
                data: json.payload,
                returnCode: json.returnCode,
                message: json.message
            };
        case UPDATE:
            return {
                data: json.payload,
                returnCode: json.returnCode,
                message: json.message
            };
        case DELETE:
            return {
                data: json.payload,
                returnCode: json.returnCode,
                message: json.message
            };
        default:
            return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    const { url, options = {} } = convertDataProviderRequestToHTTP(type, resource, params);
    const token = localStorage.getItem('token');

    if (!options.headers) {
        options.headers = new Headers({});
    }

    if(token) {
        options.headers.set('X-Auth-Token', token);
    }

    return fetchJson(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};