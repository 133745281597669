import React from 'react';
import Card from '@material-ui/core/Card';
import { translate } from 'react-admin';
import Image from './components/Image';
import './styles/dashboard.scss';

const Dashboard = ({ translate }) => (
    <Card className="dashboard">
        <Image 
            className="image" 
            src={"/images/Logo_400.png"}
            fallbackSrc={"/images/image-not-found.jpg"} />
    </Card>
);

export default translate(Dashboard);