import React from 'react';
import { Toolbar, SaveButton, Labeled, NumberInput, required, ImageField, Edit, SimpleForm, DisabledInput, SelectInput, FormDataConsumer } from 'react-admin';

const RestaurantMediaTitle = ({ record }) => {
    return <span>Restaurant Media {record ? `"${record.mediaKey}"` : ''}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const RestaurantMediaEdit = props => {
    
    const getMediaType = mediaType => {
        if (mediaType === 'REST_LARGE_IMAGE' || mediaType === 'REST_LOGO_IMAGE') return [{ id: 'REST_LARGE_IMAGE', name: 'Image(720*320)' }, { id: 'REST_LOGO_IMAGE', name: 'Icon(480*360)' }];
        if (mediaType === 'FOLDER_LARGE_IMAGE' || mediaType === 'FOLDER_LOGO_IMAGE') return [{ id: 'FOLDER_LARGE_IMAGE', name: 'Image' }, { id: 'FOLDER_LOGO_IMAGE', name: 'Icon' }];
        return [];
    };

    return <Edit title={<RestaurantMediaTitle />} {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
            {/* <SelectInput source="mediaType" choices={[
                { id: 'REST_LARGE_IMAGE', name: 'REST_LARGE_IMAGE' },
                { id: 'REST_LOGO_IMAGE', name: 'REST_LOGO_IMAGE' },
                { id: 'ECOUPON_LARGE_IMAGE', name: 'ECOUPON_LARGE_IMAGE' },
                { id: 'FOLDER_LOGO_IMAGE', name: 'FOLDER_LOGO_IMAGE' },
                { id: 'DEFAULT_MENU_ITEM_ICON', name: 'DEFAULT_MENU_ITEM_ICON' },
                { id: 'FOLDER_LARGE_IMAGE', name: 'FOLDER_LARGE_IMAGE' }
            ]} /> */}
            {/* <DisabledInput source="mediaType" /> */}
            <FormDataConsumer>
                {({ formData, ...rest }) => <SelectInput source="mediaType" choices={getMediaType(formData.mediaType)} validate={[required()]} />}
            </FormDataConsumer>
            <DisabledInput source="mediaKey" />
            <NumberInput source="sequence" />
            <DisabledInput source="fileType" />
            <Labeled label="Origin picture">
                <ImageField source="mediaCosUrl"/>
            </Labeled>
            {/* <ImageInput source="pictures" label="New picture" accept="image/*" multiple={true} >
                <ImageField source="src" title="title" />
            </ImageInput> */}
            <SelectInput source="status" choices={[
                { id: 'A', name: 'A' },
                { id: 'D', name: 'D' }
            ]} />
        </SimpleForm>
    </Edit>
}

export default RestaurantMediaEdit;