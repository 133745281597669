import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: { background :
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)' }
});

const CustomLoginForm = withStyles({
    button: { background: '#F15922' },
})(LoginForm);

const CustomLoginPage = props => (
    <Login
        loginForm={<CustomLoginForm />}
        backgroundImage={null}
        {...props}
    />
);

export default withStyles(styles)(CustomLoginPage);