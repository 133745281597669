import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, reset, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    CREATE,
    NumberInput, 
    ImageInput, 
    ImageField,
    TextInput,
    SelectInput,
    number,
    minValue
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import dataProvider from '../dataProvider';
import addUploadFeature from '../addUploadFeature';

class RestaurantMediaQuickCreateButton extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('restaurant-media-quick-create');
    };

    handleSubmit = values => {
        const { reset, fetchStart, fetchEnd, showNotification } = this.props;
        let mediaKey = values.mediaKey;
        
        if(!mediaKey){
            this.props.showNotification('Missing media key', 'error');
        } else {
            // Dispatch an action letting react-admin know a API call is ongoing
            fetchStart();

            const uploadCapableDataProvider = addUploadFeature(dataProvider);
            // As we want to know when the new post has been created in order to close the modal, we use the
            // dataProvider directly
            uploadCapableDataProvider(CREATE, 'restaurant/media', { data: values })
                .then(({ data }) => {
                    // console.log('JSON.stringify(data.id)=['+JSON.stringify(data.id)+']');
                    // console.log('JSON.stringify(record.id)=['+JSON.stringify(record.id)+']');

                    // dataProvider(GET, '/admin/wechat/restaurant/display', { id: record.id, data: updatedRecord })
                    // .then(() => {
                    //     // showNotification('Comment approved');
                    //     // push('/comments');
                        
                    // })
                    // .catch((e) => {
                    //     showNotification('Error: comment not approved', 'warning')
                    // });

                    // dataProvider(GET_ONE, 'wechat/restaurant/display', { id: record.id })
                    // .then(response => console.log(response));
                    // {
                    //     data: { id: 123, title: "hello, world" }
                    // }
                    
                    // Update the main react-admin form (in this case, the comments creation form)
                    // change(REDUX_FORM_NAME, 'post_id', JSON.stringify(data.id));
                    // console.log("REDUX_FORM_NAME=["+REDUX_FORM_NAME+"]");
                    
                    // change(REDUX_FORM_NAME, 'post_id', 'test');
                    // change(REDUX_FORM_NAME, 'post_id', data.payload.id);

                    
                    reset('restaurant-media-quick-create');
                    
                    this.setState({ showDialog: false,
                     });

                     window.location.reload();
                })
                .catch(error => {
                    showNotification(error.message, 'error');
                })
                .finally(() => {
                    // Dispatch an action letting react-admin know a API call has ended
                    fetchEnd();
                });
            }
    };

    getMediaType = displayType => {
        if (displayType === 'REST') return [{ id: 'REST_LARGE_IMAGE', name: 'Image(720*320)' }, { id: 'REST_LOGO_IMAGE', name: 'Icon(480*360)' }];
        if (displayType === 'FOLDER') return [{ id: 'FOLDER_LARGE_IMAGE', name: 'Image' }, { id: 'FOLDER_LOGO_IMAGE', name: 'Icon' }];
        return [];
    };
    
    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record } = this.props;

        let mediaKey = (record.displayType === 'REST')? record.restUrlId : record.folder ? record.folder.folderCode : null;

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="ra.action.create">
                    <IconContentAdd />
                </Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Create Restaurant Media"
                >
                    <DialogTitle>Create Restaurant Media</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form="restaurant-media-quick-create"
                            resource="restaurant/media"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                            defaultValue={{
                                mediaKey,
                                status : 'A'
                            }}
                        >
                            <SelectInput source="mediaType" choices={this.getMediaType(record.displayType)} validate={[required()]} />
                            <TextInput source="mediaKey" disabled={true} />
                            <NumberInput source="sequence" validate={[required(), number(), minValue(0)]} />
                            <ImageInput source="pictures" label="New picture" accept="image/*" multiple={false} validate={[required()]} >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                            <SelectInput source="status" choices={[
                                { id: 'A', name: 'A' },
                                { id: 'D', name: 'D' }
                            ]} validate={[required()]} />
                        </SimpleForm>

                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}


const mapStateToProps = state => ({
    isSubmitting: isSubmitting('restaurant-media-quick-create')(state)
});

const mapDispatchToProps = {
    change,
    reset,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
    RestaurantMediaQuickCreateButton
);