import React from 'react';
import {
    Show,
    NumberField,
    SimpleShowLayout,
    DateField,
    Filter,
    List,
    Datagrid,
    SelectInput,
    TextField,
    TextInput,
    ShowButton,
    DateInput
} from 'react-admin';
import TakeawayRefundRecord from './TakeawayRefundRecord';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';

const styles = {
    form_field: { display: 'inline-block', marginRight: 32 },
};

const enhance = compose(    
    withStyles(styles)
);

const CustomFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Tran Ref" source="tranRef" alwaysOn resettable />
        <DateInput label="From" source="dateFrom" alwaysOn resettable />
        <DateInput label="To" source="dateTo" alwaysOn resettable />
        <TextInput label="Rest Url Id" source="restUrlId" alwaysOn resettable />
        <TextInput label="Description" source="description" alwaysOn resettable />
        <TextInput label="Currency Code" source="currencyCode" resettable />
        <TextInput label="Amount >=" source="amountFrom" alwaysOn resettable />
        <TextInput label="Amount <=" source="amountTo" alwaysOn resettable />
        <TextInput source="takeawayStatus" alwaysOn resettable />
        <SelectInput source="refundType"
            choices={[
                { id: 'FULL', name: 'Full' },
                { id: 'PARTIAL', name: 'Partial' }
            ]} alwaysOn resettable />
        <TextInput source="refundStatus" alwaysOn resettable />
    </Filter>
);
    
export const TakeawayRefundList = ({ permissions, ...props }) => (
    <List 
        title="Takeaway - List"
        {...props} bulkActionButtons={null} 
        filters={<CustomFilter />} 
        actions={null}
        sort={{ field: 'createTimestamp', order: 'DESC' }} 
        >
        <Datagrid>
            <TextField source="tranRef" />            
            <DateField source="createTimestamp" label="Date" />
            <TextField source="restUrlId" />
            <TextField source="description" />
            <TextField source="currencyCode" />
            <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} />
            <TextField source="paymentCode" />
            <DateField source="paymentDate" />
            <TextField source="takeawayStatus" />
            <TextField source="refundType" />
            <TextField source="refundStatus" />
            <NumberField source="refundAmount" options={{ style: 'currency', currency: 'USD' }} />
            <ShowButton />
        </Datagrid>
    </List>
);

const FullNameField = ({ record = {} }) => <span>{record.currencyCode} {record.refundAmount}</span>;
FullNameField.defaultProps = { label: 'Name' };

export const TakeawayRefundShow = enhance(({ classes, ...props }) => {
    return (
    <Show {...props} actions={null} aside={<TakeawayRefundRecord />} >
        <SimpleShowLayout >
            <DateField source="createTimestamp" label="Date" className={classes.form_field} />
            <TextField source="restUrlId" className={classes.form_field} />
            <></>
            <TextField source="tranRef" className={classes.form_field} />
            <DateField source="tranDate" className={classes.form_field} />
            <></>
            <TextField source="description" className={classes.form_field} />
            <></>            
            <TextField source="paymentCode" className={classes.form_field} />
            <DateField source="paymentDate" className={classes.form_field} />
            <></>
            <TextField source="currencyCode" className={classes.form_field} />
            <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} className={classes.form_field} />         
            <></>
            <TextField source="status" label="Payment Status" className={classes.form_field} />
            <TextField source="takeawayStatus" className={classes.form_field} />
            <></>
            <TextField source="refundType" className={classes.form_field} />               
            <NumberField source="refundAmount" options={{ style: 'currency', currency: 'USD' }} className={classes.form_field} />
            <TextField source="refundStatus" className={classes.form_field} />
        </SimpleShowLayout>
    </Show>
    )
});