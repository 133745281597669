import React from 'react';
import { 
    CardActions,
    RefreshButton,
    Filter,
    List,
    SelectInput,
    TextInput 
} from 'react-admin';
import GridList from './GridList';

export const RestaurantMediaFilter = (props) => (
    <Filter {...props}>
        <TextInput source="mediaKey" alwaysOn resettable />
        <SelectInput source="mediaType" choices={[
                { id: 'REST_LARGE_IMAGE', name: 'REST_LARGE_IMAGE' },
                { id: 'REST_LOGO_IMAGE', name: 'REST_LOGO_IMAGE' },
                { id: 'ECOUPON_LARGE_IMAGE', name: 'ECOUPON_LARGE_IMAGE' },
                { id: 'FOLDER_LOGO_IMAGE', name: 'FOLDER_LOGO_IMAGE' },
                { id: 'DEFAULT_MENU_ITEM_ICON', name: 'DEFAULT_MENU_ITEM_ICON' },
                { id: 'FOLDER_LARGE_IMAGE', name: 'FOLDER_LARGE_IMAGE' }
            ]}  />
        <SelectInput source="status" choices={[
                { id: 'A', name: 'A' },
                { id: 'D', name: 'D' }
            ]} />
    </Filter>
);

const RestaurantMediaActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
        <CardActions>
            {bulkActions && React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <RefreshButton />
        </CardActions>
    );

const RestaurantMediaList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<RestaurantMediaFilter />}
        perPage={25}
        sort={{ field: 'updateTimestamp', order: 'DESC' }}
        actions={<RestaurantMediaActions permissions={permissions} />}
    >
        <GridList />
    </List>
);

export default RestaurantMediaList;