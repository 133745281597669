import message from 'ra-language-chinese-traditional';

export default {
    ...message,
    'wechat/folder': 'Wechat Folder',
    'wechat/restaurant': 'Wechat Restaurant',
    'restaurant/media': 'Wechat Media',
    'refund/takeaway': 'Refund - Takeaway',
    pos: {
        no_customizable_restaurant: '沒有可定制的餐廳',
        delete: '刪除',
        remove_custom: '取消定制',
        clone: '複製定制',
        save: '儲存',
        create: '新增',
        status: '狀態',
        interval: '時間間隔(毫秒)',
        ratio: '比率',
        the_gulu: 'THE GULU',
        restaurant: '餐廳',
        preview: '預覽',
        showResult: '結果檢示',
        media: '媒體',
        dnd_hint: '請拖放檔案在此 或 點擊選擇檔案',
        search: '搜尋',
        configuration: '設置',
        slideshow_template: '幻燈片(GULU)',
        slideshow_adv: '幻燈片(廣告)',
        advertisement: '廣告',
        language: '語言',
        details: '詳細資料',
        name: '名稱',
        sequence: '次序',
        file_name: '檔案名稱',
        slideshow: {
            title : '幻燈片',
            sov: 'SOV',
            schedule: {
                title: '時間表',
                start_date: '開始日期',
                end_date: '結束日期',
                active: '正在生效中',
                type: '類型',
                limited_period: '期間限定',
                daily_use: '日常使用',
                status: '狀態',
                selected: '已選擇',
            },
            overlapped: '[日期重疊]'
        },
        type: '媒體類型',
        theme: {
            name: '主題',
            light: '白色',
            dark: '黑色',
        },
        dialog: {       
            title: '訊息',
            ok: '確定',
            cancel: '取消',
            close: '關閉',
            slideshow_success: {
                content: '成功!',
                confirm: '確定',
            },
            slideshow_delete: {
                content: '確認取消定制？',
                confirm: '確認',
            },
            slideshow_customize: {
                content: '你是否定制 %{name} 的幻燈片?'
            },
            slideshow_file_over_size: {
                content: '檔案太大'
            },
            slideshow_show_result: {
                content: '結果需要在儲存後才會生效, 請確認你已經儲存.',
                confirm: '確認',
            },
        },
        auto_complete: {
            group: {
                placeholder: '選擇集團...'
            },
            brand: {
                placeholder: '選擇品牌...'
            },
            restaurant: {
                placeholder: '選擇餐廳...',
                customized: '[定制]'
            },
            template: {
                placeholder: '選擇模板...'
            },
        },
        media_library: {
            title: '媒體庫',
            image_size: '圖片尺寸: 1920px * 1080px',
            image_file_size: '圖片檔案大小上限: 2MB',
            movie_file_size: '影片檔案大小上限: 100MB',
        }
    }
};