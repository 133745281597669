import React from 'react';
import uniqueId from 'lodash/uniqueId';
import Sortable from 'react-sortablejs'
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Image from './Image';
import { translate } from 'react-admin';
import '../styles/slideshowMediaList.scss';

class SlideshowMediaList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
    }

    removeMediaMap(slideshowTemplateMediaMap) {
        const { template } = this.props;

        let index = 0;

        for (let item of template.slideshowTemplateMediaMapList) {
            if (item.id === slideshowTemplateMediaMap.id) {
                template.slideshowTemplateMediaMapList.splice(index, 1);
                break;
            }

            index++;
        }

        this.props.handleMediaChange(template);
    }

    render() {
        const { template, translate } = this.props;
        const { slideshowTemplateMediaMapList } = template;

        return (
            <div className="slideshow-media-list">
                <Typography variant="headline" component="h5" >{translate('pos.media')}</Typography>
                <Sortable
                    onChange={(order, sortable, evt) => {
                        let list = order.map((id) => {
                            return slideshowTemplateMediaMapList[id];
                        });

                        let template = {
                            ...this.props.template,
                            slideshowTemplateMediaMapList: list
                        };

                        this.props.handleMediaChange(template);
                    }}
                >
                    {
                        template && template.slideshowTemplateMediaMapList.map((slideshowTemplateMediaMap, index) =>
                            <Card data-id={index} key={uniqueId()} className="card">
                                <div className="imageWrapper">
                                    {slideshowTemplateMediaMap.slideshowMedia.mediaType === "IMAGE" &&                                        
                                        <Image 
                                            className="image" 
                                            src={slideshowTemplateMediaMap.slideshowMedia.mediaPath} 
                                            fallbackSrc={"/images/image-not-found.jpg"} />
                                    }
                                    {slideshowTemplateMediaMap.slideshowMedia.mediaType === "VIDEO" &&
                                        <div className="video">
                                            <video width="400" controls>
                                                <source src={slideshowTemplateMediaMap.slideshowMedia.mediaPath} type="video/mp4" />
                                                Your browser does not support the video tag.
                                        </video>
                                        </div>
                                    }

                                </div>
                                <div className="details">
                                    <CardContent className="content">
                                        <Typography variant="body1">
                                            {translate('pos.sequence')}: {index + 1}
                                        </Typography>
                                        <Typography variant="body1">
                                            {translate('pos.file_name')}:{slideshowTemplateMediaMap.slideshowMedia.mediaUrl}
                                        </Typography>
                                        <Typography variant="body1">
                                            {translate('pos.type')}: {slideshowTemplateMediaMap.slideshowMedia.mediaType}
                                        </Typography>
                                        {/* <Typography variant="body1" color="textSecondary">
                                            Start Date: {moment(template.startDisplayTimestamp).format('lll')}
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            End Date: {moment(template.endDisplayTimestamp).format('lll')}
                                        </Typography> */}
                                    </CardContent>
                                </div>
                                <div className="delete-icon">
                                    <i className="material-icons"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.removeMediaMap(slideshowTemplateMediaMap);
                                        }}
                                    >remove_circle_outline</i>
                                </div>
                            </Card>
                        )
                    }
                </Sortable>
            </div>
        );
    }
}

SlideshowMediaList.propTypes = {
    handleMediaChange: PropTypes.func,
    template: PropTypes.object,
}

export default translate(SlideshowMediaList);

