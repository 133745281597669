import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';


class AutoComplete extends Component {
    render() {
        const { selectedOption, handleChange, records, placeholder, valueParameterName, labelParameterName } = this.props;

        return (
            <Select
                isClearable={this.props.isClearable}
                placeholder={placeholder}
                value={selectedOption}
                onChange={handleChange}
                options={records && records.map(record => ({ value: record[valueParameterName], label: record[labelParameterName] }))}
            />
        )
    }
}

AutoComplete.propTypes = {
    placeholder: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired, 
    valueParameterName: PropTypes.string.isRequired,
    labelParameterName: PropTypes.string.isRequired,
    selectedOption: PropTypes.object,
    records: PropTypes.array,
    isClearable: PropTypes.bool.isRequired
};

export default AutoComplete;