import React from 'react';
import {
    CardActions,
    CreateButton,
    RefreshButton,
    ReferenceManyField,
    FormDataConsumer,
    DateField,
    Toolbar,
    Filter,
    FormTab,
    TabbedForm,
    NumberInput,
    BooleanInput,
    ImageField,
    List,
    Datagrid,
    Edit,
    SelectInput,
    TextField,
    TextInput,
    Create,
    EditButton,
    required,
    minValue,
    number,
    choices,
    regex
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import RestaurantMediaQuickCreateButton from './restaurantMedia/RestaurantMediaQuickCreateButton';
import withStyles from '@material-ui/core/styles/withStyles';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import FolderLogo from './components/folder/FolderLogo';
import CustomButton from './components/CustomButton';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
const validateStatus = choices(['A', 'D', 'P'], 'Must be A, D or P');
const validateFolderCode = regex(/^\w+$/, 'Must be a valid folder Code. (e.g., DEMO_01)');

const dateParser = v => {
    if (isNaN(new Date(v))) {
        return;
    }

    return new Date(v).getTime();
};

const FolderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Folder Name" source="name" alwaysOn resettable />
        <TextInput label="Folder Code" source="folderCode" alwaysOn resettable />
        <SelectInput source="status"
            choices={[
                { id: 'A', name: 'A' },
                { id: 'D', name: 'D' },
                { id: 'P', name: 'P' }
            ]} alwaysOn resettable />
    </Filter>
);

const WechatFolderActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </CardActions>
);
    
export const WechatFolderList = ({ permissions, ...props }) => (
    <List 
        title="Wechat Folder - List"
        {...props} bulkActionButtons={null} 
        filters={<FolderFilter />} 
        sort={{ field: 'sequence', order: 'DESC' }} 
        actions={<WechatFolderActions 
        permissions={permissions} />} >
        <Datagrid>
            <FolderLogo />
            <TextField source="folder.tcName" label="Name(TC)" sortBy="wechat_folder_info.tcName" />
            <TextField source="folder.folderCode" label="Folder Code" sortBy="wechat_folder_info.folderCode" />
            <TextField source="sequence" />
            <TextField source="status" sortBy="wechat_restaurant_display.status" />
            <EditButton />
        </Datagrid>
    </List>
);

const styles = {
    form: { maxWidth: 1000 },
    en_name: { display: 'inline-block', marginRight: 32 },
    tc_name: { display: 'inline-block', marginRight: 32 },
    sc_name: { display: 'inline-block', marginRight: 32 },
    en_short_desc: { display: 'inline-block', marginRight: 32 },
    tc_short_desc: { display: 'inline-block', marginRight: 32 },
    sc_short_desc: { display: 'inline-block', marginRight: 32 },
    seq: { display: 'inline-block', marginRight: 32 },
    landing_page_seq: { display: 'inline-block', marginRight: 32 },
    status: { display: 'inline-block', marginRight: 32 },
};

const AddNewRestaurantButton = ({ record }) => {
    return <Button
        component={Link}
        to={{
            pathname: '/wechat/restaurant/create',
            search: `?folderId=${record.id}&refFolderCode=${record.folder.folderCode}`
        }}
        label="Add a restaurant"
    >
        <ChatBubbleIcon />
    </Button>
};

const editRedirect = (basePath, id, ...rest) => {
    let path = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    if(path === "image") {
        return `${basePath}/${id}/restaurant`
    }

    if(path === "restaurant") {
        return `${basePath}`
    }
    return `${basePath}/${id}/image`
};

const needToSubmit = () => {
    let path = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    if(path === "image") {
        return false;
    }
    
    if(path === "restaurant") {
        return false;
    }
    return true;
};

const EditToolbar = props => {
    let path = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    return <Toolbar {...props} >
        <CustomButton
            type="UPDATE"
            label={path === "restaurant" ? 'FINISH' : 'NEXT'}
            resourceName="wechat/folder"
            redirect={editRedirect}
            needToSubmit={needToSubmit()}
        />
    </Toolbar>
};

const enhance = compose(
    connect(
        null,
        {
            reset
        }
    ),
    withStyles(styles)
);

const WechatFolderEditTitle = ({ record }) => {
    return <span>Wechat Folder - {record ? `[${record.folder.tcName}]` : ''}</span>;
};

const validate = (values) => {
    
    const errors = {};

    //Y2K38 issue
    if (new Date(values.startDisplayTimestamp).getYear() >= 138) {
        errors.startDisplayTimestamp = ['The start date should be before 2038.'];
    }

    if (new Date(values.endDisplayTimestamp).getYear() >= 138) {
        errors.endDisplayTimestamp = ['The end date should be before 2038.'];
    }

    return errors
};

export const WechatFolderEdit = enhance(({ reset, classes, ...props }) => {
    return <Edit title={<WechatFolderEditTitle />} {...props} className={classes.form}>
        <TabbedForm form="wechat-folder-edit" destroyOnUnmount toolbar={<EditToolbar />} validate={validate} >
            <FormTab label="1. Folder">  
                <TextInput source="folder.folderCode" label="Folder Code" disabled={true} />
                <TextInput source="folder.enName" label="Name(EN)" validate={required()} resettable formClassName={classes.en_name} />
                <TextInput source="folder.tcName" label="Name(TC)" validate={required()} resettable formClassName={classes.tc_name} />
                <TextInput source="folder.scName" label="Name(SC)" validate={required()} resettable formClassName={classes.sc_name} />
                <TextInput source="enShortDescription" label="Description(EN)" resettable formClassName={classes.en_short_desc} />           
                <TextInput source="tcShortDescription" label="Description(TC)" resettable formClassName={classes.tc_short_desc} />
                <TextInput source="scShortDescription" label="Description(SC)" resettable formClassName={classes.sc_short_desc} />
                <NumberInput source="sequence" validate={[required(), number(), minValue(0)]} formClassName={classes.seq} />
                <NumberInput source="landingPageSequence" validate={[required(), number(), minValue(0)]} formClassName={classes.landing_page_seq} />
                <SelectInput source="status" choices={[
                    { id: 'A', name: 'A' },
                    { id: 'D', name: 'D' },
                    { id: 'P', name: 'P' }
                ]} validate={[required(), validateStatus]} formClassName={classes.status} />
                <BooleanInput source="showFilter" />
                <BooleanInput source="landingDisplay" />
                <BooleanInput source="hasIconImage" />
                <TextInput source="action" resettable />
                <DateTimeInput source="startDisplayTimestamp" label="Start Display Timestamp" options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />
                <DateTimeInput source="endDisplayTimestamp" label="End Display Timestamp" options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />
            </FormTab>
            <FormTab label="2. Image" path="image">
                <ReferenceManyField
                    reference="restaurant/media"
                    target="mediaKey"
                    source="folder.folderCode"
                    addLabel={false} >
                    <Datagrid>
                        <ImageField source="mediaCosUrl" />
                        <TextField source="mediaType" />
                        <TextField source="status" />
                        <DateField source="updateTimestamp" showTime />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField >                
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <RestaurantMediaQuickCreateButton {...rest} />
                    }
                </FormDataConsumer>
            </FormTab >
            <FormTab label="3. Restaurant" path="restaurant">
                <ReferenceManyField
                    sort={{ field: 'sequence', order: 'DESC' }} 
                    reference="wechat/restaurant"
                    target="refFolderCode"
                    source="folder.folderCode"
                    addLabel={false} >
                    <Datagrid>
                        <TextField source="restUrlId" label="Rest Url Id" sortBy="restaurant.restUrlId" />
                        <TextField source="restaurant.tcName" label="Name" />
                        <TextField source="sequence" />                        
                        <TextField source="status" sortBy="wechat_restaurant_display.restUrlId" />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
                <AddNewRestaurantButton />
            </FormTab>
        </TabbedForm>
    </Edit>
});

const CreateToolbar = props => (
    <Toolbar {...props} >
        <CustomButton
            type="CREATE"
            label="NEXT"
            resourceName="wechat/folder"
            redirect={editRedirect}            
            needToSubmit={needToSubmit()}
        />
    </Toolbar>
);

export const WechatFolderCreate = enhance(({ reset, classes, ...props }) => {
    return <Create title="Wechat Folder - Create" {...props} className={classes.form}>
        <TabbedForm form="wechat-folder-create" destroyOnUnmount toolbar={<CreateToolbar />} defaultValue={{ status: 'A', hasIconImage: true, showFilter: true, startDisplayTimestamp: new Date().getTime(), endDisplayTimestamp: new Date(new Date().setFullYear(new Date().getFullYear() + 10)).getTime() }} validate={validate} >
            <FormTab label="1. Folder">
                <TextInput source="folder.folderCode" label="Folder Code" validate={[required(), validateFolderCode]} resettable />
                <TextInput source="folder.enName" label="Name(EN)" validate={required()} resettable formClassName={classes.en_name} />
                <TextInput source="folder.tcName" label="Name(TC)" validate={required()} resettable formClassName={classes.tc_name} />
                <TextInput source="folder.scName" label="Name(SC)" validate={required()} resettable formClassName={classes.sc_name} />
                <TextInput source="enShortDescription" label="Description(EN)" resettable formClassName={classes.en_short_desc} />           
                <TextInput source="tcShortDescription" label="Description(TC)" resettable formClassName={classes.tc_short_desc} />
                <TextInput source="scShortDescription" label="Description(SC)" resettable formClassName={classes.sc_short_desc} />
                <NumberInput source="sequence" validate={[required(), number(), minValue(0)]} formClassName={classes.seq} />
                <NumberInput source="landingPageSequence" validate={[required(), number(), minValue(0)]} formClassName={classes.landing_page_seq} />
                <SelectInput source="status" choices={[
                    { id: 'A', name: 'A' },
                    { id: 'D', name: 'D' },
                    { id: 'P', name: 'P' }
                ]} validate={[required(), validateStatus]} formClassName={classes.status} />
                <BooleanInput source="showFilter" />
                <BooleanInput source="landingDisplay" />         
                <BooleanInput source="hasIconImage" />
                <TextInput source="action" resettable />
                <DateTimeInput source="startDisplayTimestamp" label="Start Display Timestamp" options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />
                <DateTimeInput source="endDisplayTimestamp" label="End Display Timestamp" options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />
            </FormTab>
            <FormTab label="2. Image" path="image" disabled={true}>
            </FormTab >
            <FormTab label="3. Restaurant" path="restaurant" disabled={true}>
            </FormTab >
        </TabbedForm>
    </Create>
});