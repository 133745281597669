import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, reset, submit } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    Button,
    SaveButton,
    CREATE,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import dataProvider from '../dataProvider';
import addUploadFeature from '../addUploadFeature';
import { push } from 'react-router-redux';
import Dropzone from 'react-dropzone'
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import '../styles/mediaLibraryButton.scss';
import ConfirmationDialog from '../components/ConfirmationDialog';

class MediaLibraryButton extends Component {
    constructor(props) {
        super(props);

        this.onDrop = this.onDrop.bind(this);

        this.state = {            
            error: false,
            showDialog: false,
            media: null,
            formData: null,
            isOverSizeDialogOpen: false
        };
    }

    onDrop(acceptedFilesList, rejectedFiles) {    
        console.log("onDrop rejectedFiles", rejectedFiles);    
        if(rejectedFiles && Object.keys(rejectedFiles).length > 0) {
            this.setState({
                showDialog: false,
                isOverSizeDialogOpen: true
            });
        }

        if (acceptedFilesList) {
            let acceptedFiles = acceptedFilesList[0];
            if(acceptedFiles) {
                let type = "";

                if (acceptedFiles.type.indexOf("image") > -1) {
                    type = "IMAGE";

                    // 2MB limit
                    if(acceptedFiles.size > 1024 * 1024 * 2) {
                        this.setState({
                            showDialog: false,
                            isOverSizeDialogOpen: true
                        });
                        return;
                    }
                }

                if (acceptedFiles.type.indexOf("video") > -1) {
                    type = "VIDEO";

                    // 100MB limit
                    if(acceptedFiles.size > 1024 * 1024 * 100) {
                        this.setState({
                            showDialog: false,
                            isOverSizeDialogOpen: true
                        });
                        return;
                    }
                }

                this.setState({
                    media: acceptedFiles,
                    formData: {
                        pictures: {
                            rawFile: acceptedFiles,
                            src: acceptedFiles.preview,
                            title: acceptedFiles.name
                        },
                        mediaType: type
                    }
                })
            }
        }
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ 
            showDialog: false,
            media: null,
            formData: null
        });
    };

    handleSubmit = () => {
        const { fetchStart, fetchEnd, showNotification, brandTemplate } = this.props;
        const { formData } = this.state;
        
        if(formData) {
            // Dispatch an action letting react-admin know a API call is ongoing
            fetchStart();

            const uploadCapableDataProvider = addUploadFeature(dataProvider);
            
            uploadCapableDataProvider(CREATE, 'slideshow/media', { data: formData })
                .then(({ data }) => {
                    let mediaMap = {
                        "slideshowMedia": {
                            "id" : data.id,
                            "mediaType": data.mediaType,
                            "mediaUrl": data.mediaUrl,
                            "mediaPath": data.mediaPath,
                            "mediaChecksum": data.mediaChecksum
                        },
                        "templateId": (brandTemplate &&brandTemplate.id) ? brandTemplate.id : null,
                        "status": "A"
                    };

                    brandTemplate.slideshowTemplateMediaMapList.push(mediaMap);

                    this.props.handleMediaChange(brandTemplate);
                    
                    this.setState({ 
                        showDialog: false,
                        media: null,
                        formData: null
                    });

                    showNotification('Upload success');
                })
                .catch(error => {
                    showNotification(error.message, 'error');
                })
                .finally(() => {
                    // Dispatch an action letting react-admin know a API call has ended
                    fetchEnd();
                });
        }
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, translate } = this.props;

        return (
            <Fragment>
                <Button variant="outlined" onClick={this.handleClick} label="ra.action.create" fullWidth>
                    <IconContentAdd />
                </Button>
                <Dialog
                    className="media-library-dialog"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Media Library"
                >
                    <DialogTitle>{translate('pos.media_library.title')}</DialogTitle>
                    <DialogContent>
                        <div>{translate('pos.media_library.image_size')}</div>
                        <div>{translate('pos.media_library.image_file_size')}</div>
                        <div>{translate('pos.media_library.movie_file_size')}</div>
                        <Dropzone accept=".jpg,.jpeg,.png,.mp4" maxSize={1024 * 1024 * 100}
                            onDrop={(accepted, rejected) => { 
                                this.onDrop(accepted, rejected) 
                            }}
                            className="dropzone" >
                            <div className="dropzone-message">{translate('pos.dnd_hint')}</div>
                        </Dropzone>
                        
                        <div className="preview">
                        { this.state.formData && this.state.formData.mediaType === "IMAGE" &&
                            <img src={this.state.formData.pictures.src} width="200" alt="" />
                        }
                        { this.state.formData && this.state.formData.mediaType === "VIDEO" &&
                            <video width="320" height="240" controls>
                                <source src={this.state.formData.pictures.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        }
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSubmit}
                        />
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmationDialog
					isOpen={this.state.isOverSizeDialogOpen}
					title={translate('pos.dialog.title')}
					content={translate('pos.dialog.slideshow_file_over_size.content')}
					confirmText={translate('pos.dialog.ok')}
					handleConfirm={()=> {
						this.setState({ isOverSizeDialogOpen: false });
					}}	
				/>
            </Fragment>
        );
    }
}

MediaLibraryButton.propTypes = {
    push: PropTypes.func,    
    handleMediaChange: PropTypes.func,
    brandTemplate: PropTypes.object,
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
    change,
    reset,
    fetchEnd,
    fetchStart,
    showNotification,
    submit,
    push
};

const enhance = compose(
    connect(
		mapStateToProps,
		mapDispatchToProps
	),
    translate
);

export default enhance(MediaLibraryButton);