import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';



function filterByPriority(userRoleList){
    if(userRoleList.filter((val) => val.includes('ROLE_REST_GROUP_ADMIN')).length) {
        return 'ROLE_REST_GROUP_ADMIN';
    }

    if(userRoleList.filter((val) => val.includes('ROLE_SYSTEM_ADMIN')).length) {
        return 'ROLE_SYSTEM_ADMIN';
    }

    if(userRoleList.filter((val) => val.includes('ROLE_ADMIN')).length) {
        return 'ROLE_ADMIN';
    }
}

export default (type, params) => {
    // called when the user attempts to log in
    // if (type === AUTH_LOGIN) {
    //     const { username } = params;
    //     localStorage.setItem('username', username);
    //     // accept all username/password combinations
    //     return Promise.resolve();
    // }
    if (type === AUTH_LOGIN) {
        // const { username, password } = params;

        var formData = new FormData();

        for (var k in params) {
            formData.append(k, params[k]);
        }

        const API_URL_AUTH = process.env.REACT_APP_API_URL_AUTH;

        const request = new Request(API_URL_AUTH, {
            method: 'POST',
            body: formData
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(function(data) {
                if(data.returnCode === 0){
                    let token = data.payload.token;
                    let userRoleList = data.payload.adminUser.userRoleList;
                    let permissions = filterByPriority(userRoleList);

                    localStorage.setItem('token', token);
                    localStorage.setItem('role', permissions);

                    if(permissions === 'ROLE_REST_GROUP_ADMIN') {
                        let restaurantGroupDisplay = data.payload.adminUser.restaurantGroupDisplay;
                        let selectedGroupOption = { value: restaurantGroupDisplay.id, label: restaurantGroupDisplay.name };
                        localStorage.setItem('selectedGroupOption', JSON.stringify(selectedGroupOption));
                    }
                    
                } else {
                    throw new Error(data.message);
                }
            })
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        return Promise.resolve();
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.resolve();
};