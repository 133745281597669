import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AutoComplete from './components/AutoComplete';
import SlideshowMediaList from './components/SlideshowMediaList';
import Button from '@material-ui/core/Button';
import { UPDATE } from 'react-admin';
import Card from '@material-ui/core/Card';
import MediaLibraryButton from './components/MediaLibraryButton';
import { showNotification, GET_ONE, GET_LIST, Loading, translate, userLogout } from 'react-admin';
import dataProvider from './dataProvider';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { errorMessages } from './errorMessages.js';
import ConfirmationDialog from './components/ConfirmationDialog';
import compose from 'recompose/compose';
import './styles/slideshowAdv.scss';

const styles = theme => ({
	paper: {
		height: 140,
		width: 100,
	},
	title: {
		'margin-bottom': theme.spacing.unit * 2,
	},
	subTitle: {
		display: 'flex',
		'align-items': 'center',
		'justify-content': 'space-between',
		'margin-bottom': theme.spacing.unit * 2,
	},
	statusWrapper: {
		display: 'flex',
		'align-items': 'center',
		'justify-content': 'space-between'
	},
	status: {
		'margin-right': theme.spacing.unit,
	},
	control: {
		padding: theme.spacing.unit * 2,
	},
	content: {
		'margin-bottom': theme.spacing.unit * 2,
		padding: theme.spacing.unit * 2,
	},
	preview: {
		'margin-bottom': theme.spacing.unit * 2,
		padding: theme.spacing.unit * 2,
	},
	media: {
		'margin-bottom': theme.spacing.unit * 2,
		padding: theme.spacing.unit * 2,
	},
	button: {
	},
	input: {
		display: 'none',
	},
	dialogPaper: {
		minWidth: '50vh',
		maxWidth: '50vh',
	},
	loading: {
		margin: 10
	}
});

class SlideshowAdv extends React.Component {
	constructor(props) {
		super(props);

		this.getTemplate = this.getTemplate.bind(this);
		this.handleRestDialogClose = this.handleRestDialogClose.bind(this);
		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.createTemplate = this.createTemplate.bind(this);
		this.validate = this.validate.bind(this);

		this.state = {
			isLoading: false,
			spacing: '16',
			selectedTemplateOption: null,
			template: {
				name: null,
				type: "ADV",
				slideshowTemplateMediaMapList: []
			},
			isRestDialogOpen: false,
			isValidationDialogOpen: false,
			isSuccessDialogOpen: false,
			isErrorDialogOpen: false,			
			errorMessage: null,
			errorCode: null,
			errorMap: undefined,
			errors: [],
			isNew: true,
			templateList: []
		};
		// preserve the initial state in a new object
		this.baseState = this.state;
	}

	componentDidMount() {
		this.doOnMountAndWhenRefreshed();
	}

	componentDidUpdate(prevProps) {

		if (prevProps.views !== this.props.views) {
			this.doOnMountAndWhenRefreshed();
		}
	}

	doOnMountAndWhenRefreshed = () => {
		const { selectedTemplateOption } = this.state;

		if (selectedTemplateOption) {
			this.getTemplate(selectedTemplateOption.value);
		}
	};

	componentWillMount() {
		this.getTemplateList();
	}

	componentWillUnmount() {
		this.setState({
			// slideshow: JSON.parse(JSON.stringify(this.baseState.slideshow))
		})
	}

	componentWillReceiveProps(nextProps) {
	}

	validate = (criteria, values, lang) => {
		const errorMap = {
			name: ''
		}
		let valid = true;

		if (criteria.name && !values.name) {
			errorMap.name = errorMessages[lang].emptySlideshowTemplateName;
			valid = false;
		}

		return { valid: valid, errorMap: errorMap };
	}
	
	handleTemplateChange = (selectedTemplateOption) => {
		this.setState({
			selectedTemplateOption
		});

		if (selectedTemplateOption) {
			this.getTemplate(selectedTemplateOption.value);
		}
	}

	getTemplateList() {

		let formData = {
			sort: { field: 'name', order: 'ASC' },
			filter: {
				type: "ADV"
			}
		}

		dataProvider(GET_LIST, 'slideshow/template', formData)
			.then((response) => {
				this.setState({
					templateList: response.data
				})
			})
			.catch(this.handleError);
	}

	getTemplate(templateId) {
		this.setState({
			template: JSON.parse(JSON.stringify(this.baseState.template))
		})

		if (templateId) {
			dataProvider(GET_ONE, 'slideshow/template', { id: templateId })
				.then((response) => {
					this.setState({
						template: response.data,
						isNew: false
					})
				})
				.catch(this.handleError);
		}
	}

	handleMediaChange = (template) => {
		this.setState({
			template
		});
	}

	handlePreviewChange = name => event => {
		const { slideshow } = this.state;
		let value = event.target.value;

		switch (name) {
			case 'interval':
				value = Number.parseInt(value);
				break;
			default:
		}

		this.setState({
			slideshow: {
				...slideshow,
				[name]: value
			}
		});

	};

	handleSubmit = values => {
		const { errors, template } = this.state;

		if (errors.length === 0) {
			const criteria = {
				name: true,
			};
			const values = {
				name: template.name,
			};

			const validateResult = this.validate(criteria, values, 'EN');

			if (validateResult.valid) {
				this.setState({
					errorMap: undefined,
				}, () => {
					this.setState({ isLoading: true });

					dataProvider(UPDATE, 'slideshow/template', { id: this.state.template.id, data: this.state.template })
					.then((response) => {
						let template = response.data;

						if(this.state.isNew) {
							this.setState({
								isNew: false,
								isSuccessDialogOpen: true,
								selectedTemplateOption: { label: template.name, value: template.id}
							});
						} else {
							this.setState({ isSuccessDialogOpen: true });
						}
					})
					.catch(this.handleError)
					.finally(() => {
						this.setState({ isLoading: false });
					});
				});
			} else {
				this.setState({
					errorMap: validateResult.errorMap,
					isValidationDialogOpen: true
				});
			}
		}
	};

	handleRestDialogClose(status) {
		this.setState({ isRestDialogOpen: false });

		if (!status) {
			const { selectedBrandOption } = this.state;

			this.setState({
				selectedRestOption: null
			});

			if (selectedBrandOption) {
				this.getBrandSlideshow(selectedBrandOption.value);
			}
		}
	}

	handleStatusChange(checked) {
		this.setState({
			template: {
				...this.state.template,
				status: checked ? "A" : "D"
			}
		});
	}

	handleDetailsChange = name => event => {
		const { template } = this.state;
		let value = event.target.value;

		this.setState({
			template: {
				...template,
				[name]: value
			}
		});

	};

	createTemplate() {
		this.resetForm();
	}

	resetForm = () => {
		this.setState(this.baseState)
	}

	handleError = (e) => {
		console.log(e);

		const error = JSON.parse(e.message);

		this.setState({
			isErrorDialogOpen: true,
			errorMessage: (error.message) ? error.message : "Error",
			errorCode: error.returnCode
		})
	}

	render() {
		const { classes, translate } = this.props;
		const { template, selectedTemplateOption, isNew } = this.state;

		return (
			<div className="slideshow-template">
				<Paper className={classes.control}>
					{
						this.state.isLoading &&
						<Dialog open={true}>
							<Loading className={classes.loading} />
						</Dialog>
					}
					<Typography variant="display1" component="h2" className={classes.title} >
						{translate('pos.slideshow_adv')}
					</Typography>
					
					<div className="template-auto-complete">
						<AutoComplete
							isClearable
							placeholder={translate('pos.auto_complete.template.placeholder')}
							handleChange={this.handleTemplateChange}
							valueParameterName="id"
							labelParameterName="name"
							selectedOption={this.state.selectedTemplateOption}
							records={this.state.templateList}
						/>
					</div>
					
					{ ( selectedTemplateOption || isNew ) &&
						<Paper className={classes.content}>
							<Card className="info">
								<Typography variant="headline" component="h5" >{translate('pos.details')}</Typography>
								<div className="details">
									{/* <div className="status row">
											<div className="label">Status:</div>
											<div className="content">
												<Switch
													onChange={this.handleStatusChange} checked={(template.status === "A" ? true : false)}
												/>
											</div>
									</div> */}
									<div className="name row">
										<div className="label">{translate('pos.name')}:</div>
										<div className="content">
											<input
												maxLength="45"
												value={template.name ? template.name : ''}
												onChange={this.handleDetailsChange('name')}
											/>
											{
											this.state.errorMap && this.state.errorMap.name &&
											<div className="error">{this.state.errorMap.name}</div>
											}
										</div>
									</div>
								</div>
							</Card>
							
							{
								// <Card className={classes.preview}>
								// 	<SlideshowPreview
								// 		slideshow={slideshow}
								// 		brandTemplate={brandTemplate}
								// 		theGuluTemplate={theGuluTemplate}
								// 		handlePreviewChange={this.handlePreviewChange}
								// 		errorMap={this.state.errorMap}
								// 		permissions={this.props.permissions}
								// 	/>
								// </Card>
							}

							<Card className={classes.media}>
								{
									template && template.slideshowTemplateMediaMapList &&
									<SlideshowMediaList
										template={template}
										handleMediaChange={this.handleMediaChange}
									/>
								}

								<MediaLibraryButton
									brandTemplate={template}
									handleMediaChange={this.handleMediaChange}
								/>
							</Card>

							<Dialog
								open={this.state.isSuccessDialogOpen}
								classes={{ paper: classes.dialogPaper }}
							>
								<DialogTitle id="alert-dialog-title">Inforamtion</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										Success
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button
										onClick={(event) => {
											event.stopPropagation();
											this.setState({ isSuccessDialogOpen: false });

											//Refresh
											if (selectedTemplateOption) {
												this.getTemplateList();
												this.getTemplate(selectedTemplateOption.value);
											}
										}}
										color="primary">
										OK
									</Button>
								</DialogActions>
							</Dialog>

							<Button
								className="save-btn"
								variant="contained" color="primary"
								onClick={this.handleSubmit}>Save</Button>

							<Button
								className="create-btn"
								variant="contained" color="primary"
								onClick={this.createTemplate}>Create</Button>
						</Paper>
					}
				</Paper>
				<ConfirmationDialog
					isOpen={this.state.isErrorDialogOpen}
					title={translate('pos.dialog.title')}
					content={this.state.errorMessage}
					confirmText={translate('pos.dialog.ok')}
					handleConfirm={()=> {
						this.setState({ 
							isErrorDialogOpen: false,
							errorMessage: null,
							errorCode: null
							});
						this.props.userLogout();
					}}	
				/>		
			</div>
		);
	}
}

SlideshowAdv.propTypes = {
	classes: PropTypes.object.isRequired,
	push: PropTypes.func,
	permissions: PropTypes.string,
};

const mapStateToProps = state => ({ 
	views: state.admin.ui.viewVersion 
});

const mapDispatchToProps = {
	showNotification,
	push,
	userLogout
};

const enhance = compose(
    connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
    translate
);

export default enhance(SlideshowAdv);