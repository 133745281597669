import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { translate } from 'react-admin';
import moment from 'moment';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import StarIcon from '@material-ui/icons/Star';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowRightAltIcon from '@material-ui/icons/TrendingFlat';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import '../styles/slideshowSchedule.scss';

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#f2f8ff"
    },
    "&$hover:hover": {
      backgroundColor: "#f2f8ff"
    }
  },
  tableCell: {
    "$selected &": {
      color: "yellow"
    }
  },
  hover: {},
  selected: {},
  activeColor: {
    background: 'linear-gradient(to right bottom, #f76b00, #fff830)'
  },
  activeAvatarColor: {
    color: '#ffffff',
    'background-color': '#f76b00'
  },
  limitedPeriodColorPrimary: {
  },
  limitedPeriodAvatarColorPrimary: {
    color: '#ffffff'
  },
  limitedPeriodColorSecondary: {
    background: 'green'
  },
  limitedPeriodAvatarColorSecondary: {
    color: '#ffffff',
    background: 'darkgreen'
  }
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



function EnhancedTableHead(props) {
  // const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, translate } = props;
  const { order, orderBy, onRequestSort, translate } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headRows = [
    { id: 'startDate', numeric: true, disablePadding: false, label: translate('pos.slideshow.schedule.start_date') },
    { id: 'endDate', numeric: true, disablePadding: false, label: translate('pos.slideshow.schedule.end_date') },
    { id: 'limitedPeriod', numeric: false, disablePadding: false, label: translate('pos.slideshow.schedule.type') },
    { id: 'status', numeric: false, disablePadding: false, label: translate('pos.slideshow.schedule.status') },    
    { id: 'active', numeric: false, disablePadding: false, label: '' },
  ];

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all desserts' }}
          />
        </TableCell> */}
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



const EnhancedTableToolbar = props => {
  // const { numSelected, translate } = props;
  const { translate } = props;

  function handleCreate(event) {
    props.handleCreateNew();
  }

  return (
    <Toolbar className="toolbar">
      <div>
        {/* {numSelected > 0 ? (
          <Typography color="inherit" variant="headline">
            {numSelected} {translate('pos.slideshow.schedule.selected')}
          </Typography>
        ) : ( */}
          <Typography variant="headline" id="tableTitle">
            {translate('pos.slideshow.schedule.title')}
          </Typography>
        {/* )} */}
      </div>
      <div />
      <div>
        { props.handleCreateNew &&
        <Button
          className=""
                variant="contained" color="primary"              
                onClick={event => handleCreate(event)}
                >{translate('pos.create')}</Button>
        }
        {/* {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};

function SlideshowSchedule(props) {
  const { records, translate, classes } = props;
  
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = records.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  React.useEffect(()=>{
      setSelected([props.selectedSlideshowId]);
      return ()=>{
      }
  }, [props.selectedSlideshowId]);

  function handleClick(event, id) {
    props.handleSlideshowChange(id);
    setSelected([id]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const isSelected = id => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);

  return (
    <div className="slideshow-schedule" >
      <Paper >
        <EnhancedTableToolbar
          translate={translate}
          handleCreateNew={props.handleCreateNew}
          // numSelected={selected.length}
          />
        <div >
          <Table
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              translate={translate}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={records.length}
            />
            <TableBody>
              {stableSort(records, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      classes={{ hover: classes.hover, selected: classes.selected }}
                      className={classes.tableRow}
                      hover
                      onClick={event => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell> */}
                      <TableCell align="right">{moment(row.startDate).format("YYYY-MM-DD")}</TableCell>
                      <TableCell align="right">{row.endDate? moment(row.endDate).format("YYYY-MM-DD") : ""}</TableCell>                      
                      <TableCell align="right">
                        <Chip
                          avatar={
                            <Avatar>
                              {
                                row.limitedPeriod === 'A' ? <AccessTimeIcon /> : <ArrowRightAltIcon />
                              }
                            </Avatar>
                          }
                          label={row.limitedPeriod === 'A' ? translate('pos.slideshow.schedule.limited_period') : translate('pos.slideshow.schedule.daily_use') }
                          color={row.limitedPeriod === 'A' ? "primary" : "secondary"}
                          classes={
                            { 
                              colorPrimary: classes.limitedPeriodColorPrimary,
                              avatarColorPrimary: classes.limitedPeriodAvatarColorPrimary,
                              colorSecondary: classes.limitedPeriodColorSecondary,
                              avatarColorSecondary: classes.limitedPeriodAvatarColorSecondary
                            }
                          }
                        />
                      </TableCell>
                      <TableCell align="right">{row.status === 'A' ? <DoneIcon/> : <CloseIcon/> }</TableCell>
                      <TableCell align="right">
                        {
                        row.active?
                        <Chip
                          avatar={
                            <Avatar><StarIcon/></Avatar>
                          }
                          label={translate('pos.slideshow.schedule.active')}
                          color={"primary"}
                          classes={
                            { 
                              colorPrimary: classes.activeColor,
                              avatarColorPrimary: classes.activeAvatarColor
                            }
                          }
                        />
                        :""
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[3, 10, 25]}
          component="div"
          count={records.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

SlideshowSchedule.propTypes = {
  records: PropTypes.array.isRequired,
  handleSlideshowChange: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func,
  selectedSlideshowId: PropTypes.string
};

const enhance = compose(
  translate,
  withStyles(styles),
);

export default enhance(SlideshowSchedule);