import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { FormLabel, RadioGroup, Radio, Card, Typography, Toolbar } from '@material-ui/core';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import { translate } from 'react-admin';
import moment from 'moment';
import compose from 'recompose/compose';
import { Decimal } from 'decimal.js';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    container: { width: '60em', marginLeft: '1em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
    takeawayItemListContainer: { margin: '1em' },
    refundType: { padding: '24px' }
};

class TakeawayRefundRecord extends Component {
    constructor(props) {
		super(props);
		
		this.state = {
            voidItemList: {},
            totalRefundAmount: 0,
            refundType: 'PARTIAL',
            error: false
		};
    }
    componentDidMount() {
    }
    
    handleStatusChange = index => event => {
        const {
            record: { takeawayItemList }
        } = this.props;    
        const { voidItemList } = this.state;
        let tempVoidItemList = Object.assign({}, voidItemList);
        let defaultRefundAmount = takeawayItemList.find(x => x.id === index).price;
        
        if(event.target.checked) {
            tempVoidItemList[index] = {
                id : index,
                refundAmount: defaultRefundAmount
            };
        } else {
            delete tempVoidItemList[index]; 
        }

        this.setState(
            {
                voidItemList: {
                    ...tempVoidItemList
                }
            },
            () => { this.calculatetotalRefundAmount(); }
        );     
    }
    handleRefundAmountChange = index => event => {
        const { voidItemList } = this.state;
        let tempVoidItemList = Object.assign({}, voidItemList);

        if(tempVoidItemList[index]) {
            tempVoidItemList[index] = {
                id: index,
                refundAmount : event.target.value
            }
            this.setState(
                {
                    voidItemList: {
                        ...tempVoidItemList
                    }
                },
                () => { this.calculatetotalRefundAmount(); }
            );  
        }
    }    
    calculatetotalRefundAmount = () => {
        const { record, showNotification } = this.props;
        const { voidItemList, refundType } = this.state;
        let tempTotalRefundAmount = 0;

        if(refundType === 'FULL') {
            tempTotalRefundAmount = record.amount;
        } else {
            Object.keys(voidItemList).forEach(function(key) {
                tempTotalRefundAmount = new Decimal(voidItemList[key].refundAmount ? voidItemList[key].refundAmount : 0 ).plus(new Decimal(tempTotalRefundAmount)).toNumber();
            });
        }

        let remainingAmount = new Decimal(record.amount).minus(new Decimal(record.refundAmount ? record.refundAmount : 0));
        let error = (new Decimal(tempTotalRefundAmount).comparedTo(remainingAmount) > 0);
        
        if(error) {
            showNotification("The refund amount is more than the remaing amount.", 'warning');
        }

        this.setState({
            totalRefundAmount: tempTotalRefundAmount,
            error
        });        
    }
    handleRefundTypeChange = event => {
        this.setRefundType(event.target.value);
    }
    setRefundType = (refundType) => {
        this.setState(
            { refundType },
            () => { this.calculatetotalRefundAmount(); }
        ); 
    }
    handleSubmit = (values) => {       
        const { record, showNotification } = this.props;
        const { voidItemList, refundType, totalRefundAmount } = this.state;
        
        var refundAmount = prompt("Please re-enter the refund amount what you confirm");
        if (!refundAmount || !new Decimal(refundAmount) || new Decimal(refundAmount).comparedTo(totalRefundAmount) !== 0) {
            return false;
        } 

        let itemList = [];

        if(refundType !== 'FULL') {
            Object.keys(voidItemList).forEach(function(key) {
                itemList.push({ id : voidItemList[key].id });
            });
        }

        const formData  = new FormData();
        
        fetch(process.env.REACT_APP_API_URL + `/refund/takeaway/void_and_refund?takeawayId=${record.takeawayId}&refundAmount=${totalRefundAmount}&itemList=${encodeURIComponent(JSON.stringify(itemList))}`, { 
                method: 'POST', 
                body: formData,
                headers: {
                    'X-Auth-Token': localStorage.getItem('token')
                },
            })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {                
                if (data.returnCode !== 0) {
                    console.error('Error: ' + data.systemMessage);
                    showNotification('Error: ' + data.systemMessage, 'warning');
                } else {
                    console.log('response data', data);
                    showNotification('Success');
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.error(err);
                showNotification(err, 'warning')
            });
    }
    render() {        
        const { classes, record } = this.props;
        const { voidItemList, totalRefundAmount, refundType, error } = this.state;     
        
        if (!record) return null;
        const { takeawayItemList, paymentStripeRefundLogList } = record;

        return (
            <Paper className={classes.container}>
                { paymentStripeRefundLogList && paymentStripeRefundLogList.length > 0 &&
                <Card className={classes.takeawayItemListContainer}>
                    <Toolbar>
                        <Typography component="h2" variant="h1">Payment Refund Record</Typography>
                    </Toolbar>
                    <Table>
                        <TableHead>                            
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell className={classes.rightAlignedCell}>Refund Amount</TableCell>
                                <TableCell className={classes.rightAlignedCell}>Status</TableCell>
                                <TableCell className={classes.rightAlignedCell}>Refund Type</TableCell>
                                <TableCell className={classes.rightAlignedCell}>Result Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentStripeRefundLogList.map(
                                (item ) =>
                                    <TableRow key={item.id}>
                                        <TableCell>          
                                            {moment(item.createTimestamp).format('YYYY-MMM-DD HH:mm:ss')}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell} >
                                            {item.refundAmount.toLocaleString(undefined, {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell} >
                                            {item.status}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell} >
                                            {item.refundType}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell} >
                                            {moment(item.resultTimestamp).format('YYYY-MMM-DD HH:mm:ss')}
                                        </TableCell>
                                    </TableRow>
                            )}
                            <TableRow>
                                <TableCell colSpan={3} />
                                <TableCell className={classes.boldCell}>Refunded Amount</TableCell>
                                <TableCell
                                    className={classnames(
                                        classes.boldCell,
                                        classes.rightAlignedCell
                                    )}
                                >
                                    {record.refundAmount.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
                }
                <Card className={classes.takeawayItemListContainer}>
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <Toolbar>
                        <Typography component="h2" variant="h1">Takeaway Item List </Typography>
                    </Toolbar>
                    <FormControl className={classes.refundType} component="fieldset">
                        <FormLabel component="legend">Refund Type</FormLabel>
                        <RadioGroup aria-label="position" name="position" 
                            value={refundType} onChange={this.handleRefundTypeChange} 
                            row
                            >
                            <FormControlLabel
                                value="FULL"
                                control={<Radio color="primary" />}
                                label="Full refund"
                                labelPlacement="end"
                                disabled={record.refundType === 'PARTIAL' ? true: false}
                            />
                            <FormControlLabel
                                value="PARTIAL"
                                control={<Radio color="primary" />}
                                label="Partial refund"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Name</TableCell>
                                <TableCell className={classes.rightAlignedCell}>Price</TableCell>
                                <TableCell className={classes.rightAlignedCell}>Status</TableCell>
                                <TableCell className={classes.rightAlignedCell}  colSpan={2}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                            
                            {takeawayItemList.map(
                                (item) =>
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            {item.itemName}
                                        </TableCell>
                                        <TableCell
                                            className={classes.rightAlignedCell}
                                        >
                                            {item.price.toLocaleString(undefined, {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                        </TableCell>
                                        <TableCell
                                            className={classes.rightAlignedCell}
                                        >
                                            {item.status}
                                        </TableCell>
                                        <TableCell
                                            className={classes.rightAlignedCell}
                                            colSpan={2}
                                        >
                                            { item.status === 'A' &&
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            icon={<CheckBoxOutlineBlankIcon />} 
                                                            checkedIcon={<IndeterminateCheckBoxIcon />} 
                                                            onChange={this.handleStatusChange(item.id)}
                                                            disabled={refundType === 'FULL' ? true: false}
                                                        />
                                                    }
                                                    label="Void"
                                                />
                                                { voidItemList[item.id] && 
                                                <FormControl className={classes.margin}>
                                                    <InputLabel htmlFor="refund-amount">Refund Amount</InputLabel>
                                                    <Input
                                                        id="refund-amount"
                                                        type="number"
                                                        onChange={this.handleRefundAmountChange(item.id)}
                                                        defaultValue={item.price}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        inputProps={{ min: "0", max: item.price, step: "0.1" }}
                                                        disabled={refundType === 'FULL' ? true: false}
                                                        />
                                                </FormControl>
                                                }
                                            </>
                                            }
                                        </TableCell>
                                    </TableRow>
                            )}
                            <TableRow>
                                <TableCell colSpan={2} />
                                <TableCell className={classes.boldCell}>Total Refund Amount</TableCell>
                                <TableCell
                                    className={classnames(
                                        classes.boldCell,
                                        classes.rightAlignedCell
                                    )}
                                >
                                    {totalRefundAmount.toLocaleString(undefined, {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                </TableCell>
                                <TableCell>
                                    <button type="sumbit" disabled={error || new Decimal(totalRefundAmount).comparedTo(0) <= 0}>Sumbit</button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </form>
                </Card>
            </Paper>
        );
    }
}

const mapStateToProps = (state, props) => {
     return {
    };
};

const mapDispatchToProps = {
	showNotification,
	push
};


const enhance = compose(
    translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(TakeawayRefundRecord);