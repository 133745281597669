import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AutoComplete from './components/AutoComplete';
import RestaurantAutoComplete from './components/RestaurantAutoComplete';
import SlideshowMediaList from './components/SlideshowMediaList';
import Button from '@material-ui/core/Button';
import { UPDATE, CREATE, DELETE } from 'react-admin';
import SlideshowPreview from './components/SlideshowPreview';
import MediaLibraryButton from './components/MediaLibraryButton';
import { showNotification, GET_ONE, GET_LIST, Loading, translate, userLogout } from 'react-admin';
import dataProvider from './dataProvider';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Switch from "react-switch";
import { errorMessages } from './errorMessages.js';
import ConfirmationDialog from './components/ConfirmationDialog';
import SlideshowSchedule from './components/SlideshowSchedule';
import compose from 'recompose/compose';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import TextField from '@material-ui/core/TextField'
import moment from 'moment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider from 'rc-slider';
import 'rc-dropdown/assets/index.css';
import './styles/slideshow.scss';

const styles = theme => ({
	header: {
		flexGrow: 1,
		'margin-bottom': theme.spacing.unit * 2,
		'z-index': 999
	},
	paper: {
		height: 140,
		width: 100,
	},
	title: {
		'margin-bottom': theme.spacing.unit * 2,
	},
	templateAutoCompleteWrapper: {
		width: 400
	},
	status: {
		'margin-right': theme.spacing.unit,
	},
	control: {
		padding: theme.spacing.unit * 2,
	},
	schedule: {
		'margin-bottom': theme.spacing.unit * 2
	},
	input: {
		display: 'none',
	},
	button: {
		'margin-top': theme.spacing.unit,
		'margin-right': theme.spacing.unit
	},
	dialogPaper: {
		minWidth: '50vh',
		maxWidth: '50vh',
	},
	loading: {
		margin: 10
	}
});


class Slideshow extends React.Component {
	constructor(props) {
		super(props);

		this.getSlideshow = this.getSlideshow.bind(this);
		this.getRestaurantList = this.getRestaurantList.bind(this);
		this.handleRestDialogClose = this.handleRestDialogClose.bind(this);
		
		this.state = {
			isNew: false,
			isLoading: false,
			isSystemAdmin: false,
			spacing: '16',
			selectedGroupOption: null,
			// selectedBrandOption: { value : "110", label : "稻香超級漁港"},		
			selectedBrandOption: null,
			selectedRestOption: null,
			selectedGuluTemplateOption: null,
			selectedAdvTemplateOption: null,
			guluTemplateList: [],
			advTemplateList: [],
			restaurantList: [],
			eligibleRestaurantList: [],
			slideshow: {
				type: "REST",
				ratioGulu: 1,
				ratioRest: 1,
				sov: null,
				limitedPeriod: 'D',
				interval: 8000,
				brandId: null,
				restUrlId: null,
				status: "A",
				slideshowTemplateList: [
					{
						"type": "BRAND",
						"status": "A",
						"slideshowTemplateMediaMapList": []
					},
					{
						"type": "THE_GULU",
						"status": "A",
						"slideshowTemplateMediaMapList": []
					}
				],
				startDate: null,
				endDate: null,
			},
			slideshowList: [],
			selectedSlideshowId: null,
			// selectedSlideshowId: "48b795d9-55d0-4785-b65a-7593328646b1",
			groupList: undefined,
			brandList: undefined,
			hasAdvertisement: false,
			isRestDialogOpen: false,
			isValidationDialogOpen: false,
			isSuccessDialogOpen: false,
			isDeleteDialogOpen: false,
			isCloneDialogOpen: false,
			isScheduleDialogOpen: false,
			isErrorDialogOpen: false,
			isShowResultDialogOpen: false,
			errorMessage: null,
			errorCode: null,
			cloneRestUrlIds: [],
			errorMap: undefined,
			errors: [],
		};
		// preserve the initial state in a new object
		this.baseState = this.state;

		this.clearSlideshow  = {
			slideshow: JSON.parse(JSON.stringify(this.baseState.slideshow)),
			slideshowList: [],
			selectedSlideshowId: null,
			selectedGuluTemplateOption: null,
			selectedAdvTemplateOption: null,
			isNew: false,
			eligibleRestaurantList: []
		};
	}

	componentDidMount() {
		this.refresh();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.views !== this.props.views) {
			this.refresh();
		}
	}

	componentWillMount() {
		// this.getSlideshow('BRAND', "110");
		this.getGroupList();
	}

	componentWillUnmount() {
		this.setState({
			slideshow: JSON.parse(JSON.stringify(this.baseState.slideshow))
		})
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.permissions !== this.props.permissions) {
			if (nextProps.permissions === 'ROLE_SYSTEM_ADMIN') {
				this.setState({ isSystemAdmin: true });
			}

			if (nextProps.permissions === 'ROLE_REST_GROUP_ADMIN') {
				let selectedGroupOption = JSON.parse(localStorage.getItem("selectedGroupOption"));
				this.setState(
					{ selectedGroupOption: selectedGroupOption },
					() => {
						this.getBrandList(selectedGroupOption.value);
					}
				);
			}
		}
	}

	refresh = () => {
		const { selectedGroupOption, selectedBrandOption, selectedRestOption } = this.state;
		
		if (selectedRestOption) {
			this.getSlideshow('REST', selectedRestOption.value);
		} else if (selectedBrandOption) {
			this.getSlideshow('BRAND', selectedBrandOption.value);
		}

		if (selectedBrandOption) {
			this.getRestaurantList(selectedBrandOption.value);
		}

		if (selectedGroupOption) {
			this.getBrandList(selectedGroupOption.value);
		}

		this.getGuluTemplateList();
		this.getAdvTemplateList();
	};

	getSlideshow(type, id) {
		const { selectedSlideshowId } = this.state;

		this.setState({
			slideshow: JSON.parse(JSON.stringify(this.baseState.slideshow))
		})

		const formData = {
			sort: { field: 'updateTimestamp', order: 'ASC' },
			filter: {
				brandId : (type === 'BRAND') ? id : null,
				restUrlId : (type === 'REST') ? id : null,
			},
		};

		dataProvider(GET_LIST, 'slideshow', formData)
			.then((response) => {
				if(response.data && response.data.length > 0) {
					let slideshowList = response.data;

					this.setState({
						slideshowList : slideshowList
					}, () => {
						if (selectedSlideshowId) {
							this.handleSlideshowChange(selectedSlideshowId);
						}	
					});					
				} else {
					this.setState({
						slideshow: this.baseState.slideshow
					})
				}
			})
			.catch(this.handleError);
	}

	getGroupList = () => {
		let formData = {
			sort: { field: 'name', order: 'ASC' },
			filter: {}
		};

		dataProvider(GET_LIST, 'restaurant_group_display', formData)
			.then((response) => {
				this.setState({
					groupList: response.data
				});					
			})
			.catch(this.handleError);
	}

	getBrandList(groupId) {
		this.setState({
			brandList: undefined
		})

		let formData = {
			sort: { field: 'enBrandName', order: 'ASC' },
			filter: {
				restGroupDisplayId: groupId
			}
		};

		if (groupId) {
			dataProvider(GET_LIST, 'restaurant_brand_display', formData)
				.then((response) => {
					this.setState({
						brandList: response.data
					});					
				})
				.catch(this.handleError);
		}
	}

	getRestaurantList(brandId) {
		let formData = {
			sort: { field: 'name', order: 'ASC' },
			filter: { brandId }
		}

		dataProvider(GET_LIST, 'slideshow/restaurant', formData)
			.then((response) => {
				this.setState({
					restaurantList: response.data
				}, ()=> {
						const {restaurantList, selectedRestOption} = this.state;
						const { translate } = this.props;

						if (restaurantList && selectedRestOption) {
							let options = restaurantList.map(val => (
								{ value: (val.restaurant) ? val.restaurant.restUrlId : null, label: val.restaurant.hasSlideshow ? translate('pos.auto_complete.restaurant.customized') + val.restaurant.tcName : (val.restaurant) ? val.restaurant.tcName : null, hasSlideshow : val.restaurant.hasSlideshow }
							));
							let selectedOption = options.find((option) => {
								return option.value === selectedRestOption.value;
							});
							this.setState({
								selectedRestOption: selectedOption
							});
						}
					}
				)
			})
			.catch(this.handleError);
	}

	getEligibleCloneRestaurantList = (slideshowId) => {
		let formData = {
			sort: { field: 'name', order: 'ASC' },
			filter: { 
				id : slideshowId,
				brandId: this.state.selectedBrandOption.value
			}
		}

		dataProvider(GET_LIST, 'restaurant_brand_display/eligible-clone', formData)
			.then((response) => {
				this.setState({
					eligibleRestaurantList: response.data
				});
			})
			.catch(this.handleError);
	}

	getGuluTemplateList = () => {
		let formData = {
			sort: { field: 'name', order: 'ASC' },
			filter: {
				type: 'THE_GULU'
			}
		}

		dataProvider(GET_LIST, 'slideshow/template', formData)
			.then((response) => {
				this.setState({
					guluTemplateList: response.data
				})
			})
			.catch(this.handleError);
	}

	getAdvTemplateList = () => {
		let formData = {
			sort: { field: 'name', order: 'ASC' },
			filter: {
				type: 'ADV'
			}
		}

		dataProvider(GET_LIST, 'slideshow/template', formData)
			.then((response) => {
				this.setState({
					advTemplateList: response.data
				})
			})
			.catch(this.handleError);
	}

	handleGroupChange = (selectedGroupOption) => {
		this.setState({
			selectedGroupOption,
			selectedBrandOption: null,
			selectedRestOption: null,
			...this.clearSlideshow
		});

		if (selectedGroupOption) {
			this.getBrandList(selectedGroupOption.value);
		}
	}

	handleBrandChange = (selectedBrandOption) => {
		this.setState({
			selectedBrandOption,
			selectedRestOption: null,
			...this.clearSlideshow
		});

		if (selectedBrandOption) {
			this.getSlideshow('BRAND', selectedBrandOption.value);
			this.getRestaurantList(selectedBrandOption.value);
		}
	}

	handleRestChange = (selectedRestOption) => {
		const { selectedBrandOption } = this.state;

		this.setState({
			selectedRestOption,
			...this.clearSlideshow
		});

		if (selectedRestOption) {
			if (selectedRestOption.hasSlideshow) {		
				this.getSlideshow('REST', selectedRestOption.value);
			} else {
				this.setState({ isRestDialogOpen: true });
			}
		} else if (selectedBrandOption) {
			this.getSlideshow('BRAND', selectedBrandOption.value);
		}
	}

	handleStartDateChange = (event) => {
		const { slideshow } = this.state;
		let startDate = event.target.value;
		let startDateTimestamp = moment(startDate, "YYYY-MM-DD").valueOf();
	
		this.setState({
			slideshow: {
				...slideshow,
				startDate: startDateTimestamp
			}
		});
	}

	handleEndDateChange = (event) => {
		const { slideshow } = this.state;
		let endDate = event.target.value;
		let endDateTimestamp = moment(endDate, "YYYY-MM-DD").valueOf();
	
		this.setState({
			slideshow: {
				...slideshow,
				endDate: endDateTimestamp
			}
		});
	}

	handleMediaChange = (brandTemplate) => {
		const { slideshow } = this.state;

		let tempList = slideshow.slideshowTemplateList.map((templateList) => {
			var temp = Object.assign({}, templateList);
			if (temp.type === 'BRAND') {
				temp = JSON.parse(JSON.stringify(brandTemplate));
			}
			return temp;
		});

		this.setState({
			slideshow: {
				...slideshow,
				slideshowTemplateList: JSON.parse(JSON.stringify(tempList))
			}
		});
	}

	handlePreviewChange = name => event => {
		const { slideshow } = this.state;
		let value = event.target.value;

		switch (name) {
			case 'interval':
				value = Number.parseInt(value);
				break;
			default:
		}

		this.setState({
			slideshow: {
				...slideshow,
				[name]: value
			}
		});

	};

	handleSubmit = values => {
		const { selectedBrandOption, selectedRestOption, selectedGuluTemplateOption, selectedAdvTemplateOption, errors, slideshow } = this.state;

		if (errors.length === 0) {
			const criteria = {
				interval: true,
				ratioGulu: true,
				ratioRest: true,
				startDate: true,
				endDate: true,
				guluTemplate: true,
				advTemplate: true
			};
			const values = {
				interval: slideshow.interval,
				ratioGulu: slideshow.ratioGulu,
				ratioRest: slideshow.ratioRest,
				startDate: slideshow.startDate,
				endDate: slideshow.endDate,
				guluTemplate: (selectedGuluTemplateOption) ? selectedGuluTemplateOption.value : '',
				advTemplate: (selectedAdvTemplateOption) ? selectedAdvTemplateOption.value : '',
			};

			const validateResult = this.validate(criteria, values, 'TC');

			if (validateResult.valid) {

				let type = selectedRestOption ? 'REST' : 'BRAND';

				this.setState({
					slideshow: {
						...this.state.slideshow,
						brandId: (type === 'BRAND' && selectedBrandOption) ? selectedBrandOption.value : null,
						restUrlId: (type === 'REST' && selectedRestOption) ? selectedRestOption.value : null,
						type: type,
					},
					errorMap: undefined,
				}, () => {
					this.setState({ isLoading: true });

					let formData = {};

					if(this.state.slideshow.limitedPeriod === 'A') {
						formData = this.state.slideshow;
					} else {
						formData = {
							...this.state.slideshow,
							endDate: null
						};
					}

					if(!this.state.hasAdvertisement){
						formData.slideshowTemplateList.forEach((templateList, index) => {
							if(templateList.type === 'ADV') {
								formData.slideshowTemplateList.splice(index, 1);
							}
						});

						formData = {
							...this.state.slideshow,
							sov: null
						};
					}

					console.log("formData", formData);
					if (this.state.isNew) {
						//CREATE slideshow
						dataProvider(CREATE, 'slideshow/brand', { data: formData })
							.then((response) => {
								this.setState({ 
									selectedSlideshowId: response.data.id,
									isSuccessDialogOpen: true,
									isNew: false
								});

								if(selectedRestOption) {
									this.getEligibleCloneRestaurantList(response.data.id);
								}
							})
							.catch(this.handleError)
							.finally(() => {
								this.setState({ 
									isLoading: false
								});
							});
					} else {
						//UPDATE slideshow
						dataProvider(UPDATE, 'slideshow/brand', { id: formData.id, data: formData })
							.then((response) => {
								this.setState({ isSuccessDialogOpen: true });
							})
							.catch(this.handleError)
							.finally(() => {
								this.setState({ isLoading: false });
							});
					}
				});
			} else {
				this.setState({
					errorMap: validateResult.errorMap,
					isValidationDialogOpen: true
				});
			}
		}
	};

	handleDelete = values => {
		this.setState({
			isDeleteDialogOpen : true
		});
	};

	handleClone = (isCloneDialogOpen) => {
		if(!isCloneDialogOpen) {
			this.setState({
				cloneRestUrlIds : []
			});
		}

		this.setState({
			isCloneDialogOpen
		});
	}

	handleShowResult = () => {
		this.setState({
			isShowResultDialogOpen : true
		});
	};

	onCloneRestChange = ({ selectedKeys }) => {
		this.setState({
			cloneRestUrlIds : selectedKeys
		});
	}

	handleCloneSubmit = type => {
		const { selectedRestOption, cloneRestUrlIds, selectedSlideshowId } = this.state;

		let formDate = {
			originSlideshowId: selectedSlideshowId,
			targetRestUrlIds: (type === 'BRAND') ? [ selectedRestOption.value ] : cloneRestUrlIds,
			type: type
		}

		this.setState({ isLoading: true });

		//clone slideshow
		dataProvider(CREATE, 'slideshow/restaurant/clone', { data: formDate })
			.then((response) => {
				this.setState({ 
					isSuccessDialogOpen: true,
					cloneRestUrlIds: []
				});
			})
			.catch(this.handleError)
			.finally(() => {
				this.setState({ isLoading: false });
			});

	};
	
	executeDelete = () => {
		const { slideshow } = this.state;

		if (slideshow.id) {
			this.setState({ isLoading: true });

			dataProvider(DELETE, 'slideshow', { id: slideshow.id})
				.then((response) => {
					this.setState({ isSuccessDialogOpen: true });
				})
				.catch(this.handleError)
				.finally(() => {
					this.setState({
						isDeleteDialogOpen: false,
						isLoading: false,
						...this.clearSlideshow
					},);
				});
		}
	}

	executeShowResult = () => {
		const { selectedBrandOption, selectedRestOption } = this.state;

		const SLIDESHOW_URL = process.env.REACT_APP_SLIDESHOW_URL;

		this.setState({ isShowResultDialogOpen: false });

		if (selectedRestOption) {
			window.open(`${SLIDESHOW_URL}?restUrlId=` + selectedRestOption.value);
		} else if (selectedBrandOption) {
			window.open(`${SLIDESHOW_URL}?brandId=` + selectedBrandOption.value);
		}
	}

	handleRestDialogClose(status) {
		this.setState({ isRestDialogOpen: false });

		if (!status) {
			const { selectedBrandOption } = this.state;

			this.setState({
				selectedRestOption: null
			});

			if (selectedBrandOption) {
				this.getSlideshow('BRAND', selectedBrandOption.value);
			}
		} else {
			// this.handleCloneSubmit('BRAND');
		}
	}

	handleLimitedChangeChange = (event) => {
		this.setState({
			slideshow: {
				...this.state.slideshow,
				limitedPeriod: event.target.value
			}			
		});
	}
	
	handleAdvertisementChange = (checked) => {
		this.setState({
			hasAdvertisement: checked
		});
	}

	handleSovChange = (value) => {
		this.setState({
			slideshow: {
				...this.state.slideshow,
				sov: value
			}
		});
	}
	
	handleStatusChange = (checked) => {
		this.setState({
			slideshow: {
				...this.state.slideshow,
				status: checked ? 'A' : 'D'
			}
		});
	}

	validate = (criteria, values, lang) => {
		const { hasAdvertisement } = this.state;

		const errorMap = {
			interval: '',
			ratioGulu: '',
			ratioRest: '',
			startDate: '',
			endDate: '',
			guluTemplate: '',
			advTemplate: ''
		}
		let valid = true;

		if (criteria.interval && !values.interval) {
			errorMap.interval = errorMessages[lang].emptyInterval;
			valid = false;
		} else if (criteria.interval && !/^[0-9]+$/i.test(values.interval)) {
			errorMap.interval = errorMessages[lang].invalidInterval;
			valid = false;
		}

		if (criteria.ratioGulu && !values.ratioGulu) {
			errorMap.ratioGulu = errorMessages[lang].emptyRatio;
			valid = false;
		} else if (criteria.ratioGulu && !/^[0-9]+$/i.test(values.ratioGulu)) {
			errorMap.ratioGulu = errorMessages[lang].invalidRatio;
			valid = false;
		}

		if (criteria.ratioRest && !values.ratioRest) {
			errorMap.ratioRest = errorMessages[lang].emptyRatio;
			valid = false;
		} else if (criteria.ratioRest && !/^[0-9]+$/i.test(values.ratioRest)) {
			errorMap.ratioRest = errorMessages[lang].invalidRatio;
			valid = false;
		}

		var startDateStr = moment(values.startDate).format("YYYY-MM-DD");

		if (criteria.startDate && !values.startDate) {
			errorMap.startDate = errorMessages[lang].invalidDate;
			valid = false;
		} else if (criteria.startDate && !moment(startDateStr, "YYYY-MM-DD").isValid()) {
			errorMap.startDate = errorMessages[lang].invalidDate;
			valid = false;
		}

		if(this.state.slideshow.limitedPeriod === 'A'){
			var endDateStr = moment(values.endDate).format("YYYY-MM-DD");

			if (criteria.endDate && !values.endDate) {
				errorMap.endDate = errorMessages[lang].invalidDate;
				valid = false;
			} else if (criteria.endDate && !moment(endDateStr, "YYYY-MM-DD").isValid()) {
				errorMap.endDate = errorMessages[lang].invalidDate;
				valid = false;
			}
		}

		if (criteria.guluTemplate && !values.guluTemplate) {
			errorMap.guluTemplate = errorMessages[lang].emptyGuluTemplate;
			valid = false;
		}

		if(hasAdvertisement){
			if (criteria.advTemplate && !values.advTemplate) {
				errorMap.advTemplate = errorMessages[lang].emptyAdvTemplate;
				valid = false;
			}
		}

		return { valid: valid, errorMap: errorMap };
	}

	handleGuluTemplateChange = (selectedGuluTemplateOption) => {
		this.setState({
			selectedGuluTemplateOption
		});

		if (selectedGuluTemplateOption) {
			this.getTemplate(selectedGuluTemplateOption.value, 'THE_GULU');
		} else {
			let slideshow = this.state.slideshow;

			slideshow.slideshowTemplateList.forEach((templateList, index) => {
				if(templateList.type === 'THE_GULU') {
					slideshow.slideshowTemplateList.splice(index, 1);
				}
			});

			this.setState({
				slideshow: {
					...slideshow,
					sov: null
				}
			});
		}
	}

	handleAdvTemplateChange = (selectedAdvTemplateOption) => {
		this.setState({
			selectedAdvTemplateOption
		});

		if (selectedAdvTemplateOption) {
			this.getTemplate(selectedAdvTemplateOption.value, 'ADV');
		}
	}
	
	handleCreateNew  = () => {		
		const { guluTemplateList } = this.state;

		let defaultTemplate = guluTemplateList.find((template) => {
			return template.defaultGuluTemplate === true
		});

		let selectedGuluTemplateOption = null;

		if(defaultTemplate) {
			selectedGuluTemplateOption = { value : defaultTemplate.id, label : defaultTemplate.name};
		}

		this.setState({
			slideshow: JSON.parse(JSON.stringify(this.baseState.slideshow)),
			selectedSlideshowId: null,
			selectedGuluTemplateOption: selectedGuluTemplateOption,
			selectedAdvTemplateOption: null,
			isNew: true,
		}, ()=> {
			if (selectedGuluTemplateOption) {
				this.getTemplate(selectedGuluTemplateOption.value, 'THE_GULU');
			}
		})
	}

	handleSlideshowChange = (selectedSlideshowId) => {
		const { slideshowList, selectedRestOption } = this.state;
		let selectedSlideshow = slideshowList.find(function(slideshow) {                                
			return slideshow.id === selectedSlideshowId
		});
		
		if(selectedSlideshow) {
			// console.log("selectedSlideshow", selectedSlideshow);

			let selectedGuluTemplateOption = undefined;
			let selectedAdvTemplateOption = undefined;
			let theGuluTemplate = selectedSlideshow.slideshowTemplateList.find((templateList) => {
				return templateList.type === 'THE_GULU';
			});

			let advTemplate = selectedSlideshow.slideshowTemplateList.find((templateList) => {
				return templateList.type === 'ADV';
			});

			if(theGuluTemplate) {
				selectedGuluTemplateOption = { value : theGuluTemplate.id, label : theGuluTemplate.name};
			}

			if(advTemplate) {
				selectedAdvTemplateOption = { value : advTemplate.id, label : advTemplate.name};
			}
			
			this.setState({
				selectedSlideshowId: selectedSlideshowId,
				slideshow: selectedSlideshow,				
				selectedGuluTemplateOption: (selectedGuluTemplateOption) ? selectedGuluTemplateOption : null,
				selectedAdvTemplateOption: (selectedAdvTemplateOption) ? selectedAdvTemplateOption : null,
				hasAdvertisement: (selectedAdvTemplateOption) ? true : false,
			})

			if(selectedRestOption) {
				this.getEligibleCloneRestaurantList(selectedSlideshowId);
			}
		}

		setTimeout(function delayToScroll() {
			document.getElementById("schedule-bottom").scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
		}, 300);
	}

	handleError = (e) => {
		console.log(e);
		const error = JSON.parse(e.message);

		console.log("typeof error.message", typeof error.message);

		this.setState({
			isErrorDialogOpen: true,
			errorMessage: (error.message) ? error.message : "Error",
			errorCode: error.returnCode
		})
	}

	getTemplate(templateId, type) {
		if (templateId && type) {
			dataProvider(GET_ONE, 'slideshow/template', { id: templateId })
				.then((response) => {
									
					let template = response.data;
					let slideshow = this.state.slideshow;

					if (slideshow) {
						// let currentTemplate = slideshow.slideshowTemplateList.find((templateList) => {
						// 	return templateList.type === type;
						// });	
						// console.log("slideshow.slideshowTemplateList start", slideshow.slideshowTemplateList);
						// currentTemplate = JSON.parse(JSON.stringify(template));
						let hasRecord = false;

						slideshow.slideshowTemplateList.forEach((templateList, index) => {
							if(templateList.type === type) {
								hasRecord = true;
								slideshow.slideshowTemplateList[index] = JSON.parse(JSON.stringify(template));
							}
						});

						if(!hasRecord) {
							slideshow.slideshowTemplateList.push(template);
						}

						// console.log("slideshow.slideshowTemplateList end", slideshow.slideshowTemplateList);

						this.setState({
							slideshow: slideshow
						});

						// let tempList = slideshow.slideshowTemplateList.map((templateList) => {
						// 	var temp = Object.assign({}, templateList);
						// 	if(temp.type === type) {
						// 		temp = JSON.parse(JSON.stringify(template));
						// 	}

						// 	return temp;
						// });

						// let selectedOption = options.find((option) => {
						// 	return option.value === selectedRestOption.value;
						// });

						// this.setState({
						// 	slideshow: {
						// 		...slideshow,
						// 		slideshowTemplateList: JSON.parse(JSON.stringify(tempList))
						// 	}
						// });
					}					
				})
				.catch(this.handleError);
		}
	}

	percentFormatter = (v) => {
		return `${v} %`;
	  }
	
	render() {
		const { classes, translate } = this.props;
		const { spacing, selectedGroupOption, selectedBrandOption, selectedRestOption, slideshow, selectedSlideshowId, errorMap, restaurantList } = this.state;
		let brandTemplate = undefined;
		let theGuluTemplate = undefined;
		let advTemplate = undefined;

		if (slideshow) {
			brandTemplate = slideshow.slideshowTemplateList.find((templateList) => {
				return templateList.type === 'BRAND';
			});

			theGuluTemplate = slideshow.slideshowTemplateList.find((templateList) => {
				return templateList.type === 'THE_GULU';
			});

			advTemplate = slideshow.slideshowTemplateList.find((templateList) => {
				return templateList.type === 'ADV';
			});
		}

		const menu = (
			<Menu
				style={{ width: 300 }}
				multiple
				onSelect={this.onCloneRestChange}
				onDeselect={this.onCloneRestChange}
				selectedKeys={this.state.cloneRestUrlIds}
			>	
				{
					this.state.eligibleRestaurantList.map(val => (
						<MenuItem disabled={val.overlapped? true: false} key={val.restaurant.restUrlId}>{val.restaurant.tcName}({val.restaurant.restUrlId}){ val.overlapped ? translate('pos.slideshow.overlapped') : '' }</MenuItem>
					))
				}
				{
					this.state.eligibleRestaurantList.length === 0 &&
					<MenuItem disabled>{translate('pos.no_customizable_restaurant')}</MenuItem>
				}
				<Divider />
				<MenuItem disabled>
					<Button
						variant="contained" color="primary"
						style={{
							cursor: 'pointer',
							pointerEvents: 'visible',
						}}
						onClick={
							() => {
								if(this.state.cloneRestUrlIds.length > 0) {
									this.handleCloneSubmit('REST');
								}
							}
						}
					>{translate('pos.dialog.ok')}
					</Button>
				</MenuItem>
			</Menu>
		);

		return (
			<div className="slideshow">
				<Paper className={classes.control}>
					{
						this.state.isLoading &&
						<Dialog open={true}>
							<Loading className={classes.loading} />
						</Dialog>
					}				
					<Typography variant="display1" component="h2" className={classes.title} >{translate('pos.slideshow.title')}</Typography>
					<Grid container className={classes.header} spacing={8}>
						<Grid item xs={12}>
							<Grid container className={classes.demo} justify="center" spacing={Number(spacing)}>
								{this.state.isSystemAdmin
									? <Grid item xs={4}>
										<AutoComplete
											isClearable
											placeholder={translate('pos.auto_complete.group.placeholder')}
											handleChange={this.handleGroupChange}
											valueParameterName="id"
											labelParameterName="tcGroupName"
											selectedOption={selectedGroupOption}
											records={this.state.groupList}
										/>
									</Grid>
									: null
								}

								<Grid item xs={this.state.isSystemAdmin ? 4 : 6}>
									<AutoComplete
										isClearable
										placeholder={translate('pos.auto_complete.brand.placeholder')}
										handleChange={this.handleBrandChange}
										valueParameterName="brandId"
										labelParameterName="tcBrandName"
										selectedOption={selectedBrandOption}
										records={this.state.brandList}
									/>
								</Grid>

								<Grid item xs={this.state.isSystemAdmin ? 4 : 6}>
									<RestaurantAutoComplete
										restaurantList={restaurantList}
										selectedOption={selectedRestOption}
										handleRestChange={this.handleRestChange}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					
					{ selectedBrandOption &&
					<Paper className="content">						
						<Typography variant="display2" component="h1" className="content-title">
							{
								selectedRestOption ? selectedRestOption.label : (selectedBrandOption ? selectedBrandOption.label : null)
							}
						</Typography>

						{ this.state.selectedBrandOption &&
						<Paper className={classes.schedule}>
							<SlideshowSchedule
								handleSlideshowChange={this.handleSlideshowChange}
								handleCreateNew={this.handleCreateNew}
								records={this.state.slideshowList}
								selectedSlideshowId={this.state.selectedSlideshowId}
							/>
							<div id="schedule-bottom"></div>
						</Paper>
						}
						{
							(selectedSlideshowId || this.state.isNew) &&
							<Paper className="details-wrapper">					
								<div className="details">
									<Typography variant="headline" component="h5" >{translate('pos.details')}</Typography>
									<div>
										{this.state.isSystemAdmin &&
										<div className="row">
											<div className="label">{translate('pos.slideshow_template')}:</div>
											<div className="content">
												<AutoComplete
													isClearable={false}
													placeholder={translate('pos.auto_complete.template.placeholder')}
													handleChange={this.handleGuluTemplateChange}
													valueParameterName="id"
													labelParameterName="name"
													selectedOption={this.state.selectedGuluTemplateOption}
													records={this.state.guluTemplateList}
												/>
												{
													this.state.errorMap && this.state.errorMap.guluTemplate &&
													<div className="error">{this.state.errorMap.guluTemplate}</div>
												}
											</div>
										</div>
										}																				
										<div className="row">
											<div className="label">{translate('pos.slideshow.schedule.type')}:</div>
											<div className="content">
											<RadioGroup
												value={this.state.slideshow.limitedPeriod}
												onChange={this.handleLimitedChangeChange}
											>
												<FormControlLabel value="A" control={<Radio />} label={translate('pos.slideshow.schedule.limited_period')} />
												<FormControlLabel value="D" control={<Radio />} label={translate('pos.slideshow.schedule.daily_use')} />
											</RadioGroup>
											</div>
										</div>
										<div className="row">
											<div className="label">{translate('pos.slideshow.schedule.start_date')}:</div>
											<div className="content">
												<TextField
													onChange={this.handleStartDateChange}
													type="date"
													className={classes.textField}
													value={ slideshow.startDate ? moment(slideshow.startDate).format("YYYY-MM-DD") : ""}												
													InputLabelProps={{
														shrink: true,
													}}
												/>
												{
													this.state.errorMap && this.state.errorMap.startDate &&
													<div className="error">{this.state.errorMap.startDate}</div>
												}
											</div>
										</div>
										<div className="row">
											<div className="label">{translate('pos.slideshow.schedule.end_date')}:</div>
											<div className="content">
												<TextField
													disabled={this.state.slideshow.limitedPeriod === 'A'? false : true}
													onChange={this.handleEndDateChange}
													type="date"
													className={classes.textField}
													value={ slideshow.endDate ? moment(slideshow.endDate).format("YYYY-MM-DD") : ""}												
													InputLabelProps={{
														shrink: true,
													}}
												/>
												{
													this.state.errorMap && this.state.errorMap.endDate &&
													<div className="error">{this.state.errorMap.endDate}</div>
												}
											</div>
										</div>
									</div>
									{/* next phrase */}
									{ false &&
									<div className="row">
										<div className="label">{translate('pos.advertisement')}:</div>
										<div className="content">
											<Switch
												onChange={this.handleAdvertisementChange} checked={this.state.hasAdvertisement}
											/>
										</div>
									</div>
									}
									{ false && this.state.isSystemAdmin && this.state.hasAdvertisement &&
										<Card className="advertisement">
											<div className="row">
												<div className="label">{translate('pos.slideshow_adv')}:</div>
												<div className="content">
													<AutoComplete
														isClearable={false}
														placeholder={translate('pos.auto_complete.template.placeholder')}
														handleChange={this.handleAdvTemplateChange}
														valueParameterName="id"
														labelParameterName="name"
														selectedOption={this.state.selectedAdvTemplateOption}
														records={this.state.advTemplateList}
													/>
													{
														this.state.errorMap && this.state.errorMap.advTemplate &&
														<div className="error">{this.state.errorMap.advTemplate}</div>
													}
												</div>
											</div>
											<div className="row">
												<div className="label">{translate('pos.slideshow.sov')}:</div>
												<div className="content">
													<Slider 
														min={0} max={100} step={10}
														value={slideshow.sov}
														onChange={this.handleSovChange}
														marks={{ 0: 0, 10: 10, 20: 20, 30: 30, 40: 40, 50: 50, 60: 60, 70: 70, 80: 80, 90: 90, 100: 100 }}
													/>
												</div>
											</div>
										</Card>
									}									
									<div className="row">
										<div className="label">{translate('pos.status')}:</div>
										<div className="content">
											<Switch
												onChange={this.handleStatusChange} checked={(slideshow.status === "A" ? true : false)}
											/>
										</div>
									</div>
								</div>
								<div className="preview">
									<SlideshowPreview
										slideshow={slideshow}
										brandTemplate={brandTemplate}
										theGuluTemplate={theGuluTemplate}
										advTemplate={advTemplate}
										handlePreviewChange={this.handlePreviewChange}
										errorMap={this.state.errorMap}
										permissions={this.props.permissions}
									/>
								</div>
								<div className="media">
									{
										brandTemplate && brandTemplate.slideshowTemplateMediaMapList &&
										<SlideshowMediaList
											template={brandTemplate}
											handleMediaChange={this.handleMediaChange}
										/>
									}
									<MediaLibraryButton
										brandTemplate={brandTemplate}
										handleMediaChange={this.handleMediaChange}
									/>
								</div>
								<Button
									className={classes.button}
									variant="contained" color="primary"
									onClick={this.handleSubmit}>{translate('pos.save')}</Button>
								{ selectedSlideshowId &&
								<Button
									className={classes.button}
									variant="contained" color="primary"
									onClick={this.handleDelete}>{(selectedRestOption && selectedRestOption.hasSlideshow)? translate('pos.remove_custom') : translate('pos.delete')}</Button>
								}
								{ selectedRestOption && selectedRestOption.hasSlideshow &&
								<Dropdown
									trigger={['click']}
									onVisibleChange={this.handleClone}
									visible={this.state.isCloneDialogOpen}
									closeOnSelect={false}
									overlay={menu}
									animation="slide-up"
								>
									<Button
									className={classes.button}
									variant="contained" color="primary"
									>{translate('pos.clone')}</Button>
								</Dropdown>
								}
								<Button
									className={classes.button}
									variant="contained" color="primary"
									onClick={this.handleShowResult}>{translate('pos.showResult')}</Button>
							</Paper>
						}
						{errorMap &&
							<Dialog
								open={this.state.isValidationDialogOpen}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>
								<DialogTitle id="alert-dialog-title">{translate('pos.dialog.title')}</DialogTitle>
								<DialogContent>
									{
										Object.keys(errorMap).map(function (error, index) {
											return <div key={index} className="message">{errorMap[error]}</div>
										})
									}
								</DialogContent>
								<DialogActions>
									<Button
										onClick={(event) => {
											event.stopPropagation();
											this.setState({ isValidationDialogOpen: false });
										}}
										color="primary">
										{translate('pos.dialog.ok')}
									</Button>
								</DialogActions>
							</Dialog>
						}

						{ selectedRestOption &&
						<ConfirmationDialog
							isOpen={this.state.isRestDialogOpen}
							title={translate('pos.dialog.title')}
							content={translate('pos.dialog.slideshow_customize.content', { name: selectedRestOption.label })}
							confirmText={translate('pos.dialog.ok')}
							handleConfirm={()=> {
								this.handleRestDialogClose(true);
							}}
							cancelText={translate('pos.dialog.cancel')}
							handleCancel={()=> {
								this.handleRestDialogClose(false);
							}}	
						/>
						}
						<ConfirmationDialog
							isOpen={this.state.isSuccessDialogOpen}
							title={translate('pos.dialog.title')}
							content={translate('pos.dialog.slideshow_success.content')}
							confirmText={translate('pos.dialog.slideshow_success.confirm')}
							handleConfirm={()=> {
								this.setState({ isSuccessDialogOpen: false });
								this.refresh();
							}}	
						/>

						<ConfirmationDialog
							isOpen={this.state.isDeleteDialogOpen}
							title={translate('pos.dialog.title')}
							content={translate('pos.dialog.slideshow_delete.content')}
							confirmText={translate('pos.dialog.slideshow_delete.confirm')}
							handleConfirm={this.executeDelete}
							cancelText={translate('pos.dialog.cancel')}
							handleCancel={()=> {
								this.setState({ isDeleteDialogOpen: false });
							}}
						/>

						<Dialog
							open={this.state.isScheduleDialogOpen}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">{translate('pos.dialog.title')}</DialogTitle>
							<SlideshowSchedule
								handleSlideshowChange={this.handleSlideshowChange}
								records={this.state.slideshowList}
							/>
						</Dialog>

						<ConfirmationDialog
							isOpen={this.state.isErrorDialogOpen}
							title={translate('pos.dialog.title')}
							content={this.state.errorMessage}
							confirmText={translate('pos.dialog.ok')}
							handleConfirm={()=> {
								if(this.state.errorCode === -1) {
									this.props.userLogout();
								}
								
								this.setState({ 
									isErrorDialogOpen: false,
									errorMessage: null,
									errorCode: null
								});
							}}	
						/>

						{/* <ConfirmationDialog
							isOpen={this.state.isShowResultDialogOpen}
							title={translate('pos.dialog.title')}
							content={translate('pos.dialog.slideshow_show_result.content')}
							confirmText={translate('pos.dialog.slideshow_show_result.confirm')}
							handleConfirm={this.executeShowResult}
							cancelText={translate('pos.dialog.cancel')}
							handleCancel={()=> {
								this.setState({ isShowResultDialogOpen: false });
							}}
						/> */}
					</Paper>
					}
				</Paper>
			</div>
		);
	}
}

Slideshow.propTypes = {
	classes: PropTypes.object.isRequired,
	push: PropTypes.func,
	permissions: PropTypes.string
};

const mapStateToProps = state => ({ 
	views: state.admin.ui.viewVersion 
});

const mapDispatchToProps = {
	showNotification,
	push,
	userLogout
};

const enhance = compose(
    connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
    translate
);

export default enhance(Slideshow);