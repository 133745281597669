import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, WithPermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import uniqueId from 'lodash/uniqueId';
import { translate } from 'react-admin';
import compose from 'recompose/compose';

const Menu = ({ translate, resources, onMenuClick, logout }) => (
    <div>
        <WithPermissions
            render={({ permissions }) => (
                resources.map(resource => {
                    return ( resource.name !== "restaurant/media" && permissions === 'ROLE_SYSTEM_ADMIN' &&
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        // primaryText={resource.name}
                        primaryText={translate(`${resource.name}`)}
                        leftIcon={React.createElement(resource.icon)}
                        onClick={onMenuClick}
                    />
                )})
            )}
        />
        <MenuItemLink
            key={uniqueId()}
            to="/slideshow"
            primaryText={translate('pos.slideshow.title')}
            leftIcon={<LabelIcon />}
            onClick={onMenuClick} />        
        <WithPermissions
            render={({ permissions }) => (
                permissions === 'ROLE_SYSTEM_ADMIN'
                    ? <MenuItemLink 
                        key={uniqueId()}
                        to="/slideshow_template"
                        primaryText={translate('pos.slideshow_template')}
                        leftIcon={<LabelIcon />}
                        onClick={onMenuClick} />
                    : null
            )}
        />
        <WithPermissions
            render={({ permissions }) => (
                permissions === 'ROLE_SYSTEM_ADMIN'
                    ? <MenuItemLink 
                        key={uniqueId()}
                        to="/slideshow_adv"
                        primaryText={translate('pos.slideshow_adv')}
                        leftIcon={<LabelIcon />}
                        onClick={onMenuClick} />
                    : null
            )}
        />
        <MenuItemLink
            key={uniqueId()}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<LabelIcon />}
            onClick={onMenuClick} />
        {/* <Responsive
            small={logout}
            medium={logout} // Pass null to render nothing on larger devices
        /> */}
    </div>
);

const mapStateToProps = state => ({
    resources: getResources(state),
});


const enhance = compose(
    withRouter,
    connect(
        mapStateToProps
    ),
    translate
);

export default enhance(Menu);

// export default withRouter(connect(mapStateToProps)(Menu));
