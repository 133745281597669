import messages from 'ra-language-english';

export default {
    ...messages,
    'wechat/folder': 'Wechat Folder',
    'wechat/restaurant': 'Wechat Restaurant',
    'restaurant/media': 'Wechat Media',
    'refund/takeaway': 'Refund - Takeaway',
    pos: {
        no_customizable_restaurant: 'No customizable restaurant',
        delete: 'Delete',
        remove_custom: 'Remove customization',
        clone: 'Copy customization',
        save: 'Save',
        create: 'Create',
        status: 'Status',        
        interval: 'Interval(Millisecond)',
        ratio: 'Ratio',
        the_gulu: 'THE GULU',
        restaurant: 'Restaurant',
        preview: 'Preview',
        showResult: 'Show result',
        media: 'Media',        
        dnd_hint: 'Drop a media to upload, or click to select it.',
        search: 'Search',
        configuration: 'Configuration',
        slideshow_template: 'Slideshow(GULU)',
        slideshow_adv: 'Slideshow(Adv.)',
        advertisement: 'Advertisement',
        language: 'Language',
        details: 'Details',
        name: 'Name',
        sequence: 'Sequence',
        file_name: 'File Name',
        type: 'Media Type',
        slideshow: {
            title : 'Slideshow',
            sov: 'SOV',
            schedule: {
                title: 'Schedule',
                start_date: 'Start Date',
                end_date: 'End Date',
                active: 'Activating',
                type: 'Type',
                limited_period: 'Limited Period',
                daily_use: 'Daily Use',
                status: 'Status',
                selected: 'selected',
            },
            overlapped: '[Overlapped date]'
        },
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dialog: {
            title: 'Message',
            ok: 'OK',
            cancel: 'Cancel',
            close: 'Close',
            slideshow_success: {
                content: 'Success!',
                confirm: 'OK',
            },
            slideshow_delete: {
                content: 'Are you sure？',
                confirm: 'Confirm',
            },
            slideshow_customize: {
                content: 'Do you want to customize the slideshow of %{name} ?'
            },
            slideshow_file_over_size: {
                content: 'The file is too large.'
            },
            slideshow_show_result: {
                content: 'The result will only be take effect after you save, please ensure that is saved.',
                confirm: 'Confirm',
            },
        },
        auto_complete: {
            group: {
                placeholder: 'Select Group...'
            },
            brand: {
                placeholder: 'Select Brand...'
            },
            restaurant: {
                placeholder: 'Select Restaurant...',
                customized: '[Customized]'
            },
            template: {
                placeholder: 'Select Template...'
            },
        },
        media_library: {
            title: 'Media Library',
            image_size: 'Image Size: 1920px * 1080px',
            image_file_size: 'Image File Size Limit: 2MB',
            movie_file_size: 'Video File Size Limit: 100MB',
        },

    }
};