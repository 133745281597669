import React from 'react';
import {
    CardActions,
    CreateButton,
    RefreshButton,
    ReferenceManyField,
    FormDataConsumer,
    DateField,
    Toolbar,
    Filter,
    FormTab,
    TabbedForm,
    NumberInput,
    BooleanInput,
    ImageField,
    List,
    Datagrid,
    Edit,
    SelectInput,
    TextField,
    TextInput,
    Create,
    EditButton,
    required,
    minValue,
    number,
    choices,
    GET_ONE,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import RestaurantMediaQuickCreateButton from './restaurantMedia/RestaurantMediaQuickCreateButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { parse } from "query-string";
import FolderLogo from './components/folder/FolderLogo';
import CustomButton from './components/CustomButton';
import compose from 'recompose/compose';
import dataProvider from './dataProvider';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { change } from 'redux-form'

const validateStatus = choices(['A', 'D', 'P'], 'Must be A, D or P');

const dateParser = v => {
    if (isNaN(new Date(v))) {
        return;
    }

    return new Date(v).getTime();
};

const RestaurantFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" label="Restaurant Name" alwaysOn resettable />
        <TextInput source="refFolderCode" label="Folder Code" alwaysOn resettable />
        <TextInput source="restUrlId" label="Rest Url Id" alwaysOn resettable />
        <SelectInput source="status" choices={[
            { id: 'A', name: 'A' },
            { id: 'D', name: 'D' },
            { id: 'P', name: 'P' }
        ]} alwaysOn resettable />
    </Filter>
);

const WechatRestaurantActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </CardActions>
);

export const WechatRestaurantList = ({ permissions, ...props }) => (
    <List 
        title="Wechat Restaurant - List"
        {...props} bulkActionButtons={null} 
        filters={<RestaurantFilter />} 
        sort={{ field: 'restaurant.tcName', order: 'ASC' }} 
        actions={<WechatRestaurantActions 
        permissions={permissions} />} >
        <Datagrid>
            <FolderLogo />
            <TextField source="restaurant.tcName" label="Name(TC)"/>
            <TextField source="refFolderCode" label="Folder Code"/>
            <TextField source="restaurant.restUrlId" label="Rest Url Id" />
            <TextField source="status" sortBy="wechat_restaurant_display.status" />
            <EditButton />
        </Datagrid>
    </List>
);

const styles = {
    form: { maxWidth: 1000 },
    form_field: { display: 'inline-block', marginRight: 32 },
};

const editRedirect = (basePath, id) => {
    let path = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    if(path === "image") {
        return `${basePath}`
    }

    return `${basePath}/${id}/image`
};

const needToSubmit = () => {
    let path = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    if(path === "image") {
        return false;
    }

    return true;
};

const EditToolbar = (props) => {
    let path = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    return <Toolbar {...props} >
        <CustomButton
            type="UPDATE"
            label={path === "image" ? 'FINISH' : 'NEXT'}
            resourceName="wechat/restaurant"
            redirect={props.redirect}
            needToSubmit={needToSubmit()}
        />
        {/* <JumpToFolderButton 
            label="Go To Folder"
             /> */}
    </Toolbar>
};

const enhance = compose(    
    connect(
        null,
        {
            push,
            change,
        }
    ),
    withStyles(styles)
);

const WechatRestaurantEditTitle = ({ record }) => {
    return <span>Wechat Restaurant - {record ? `[${record.restaurant.tcName}]` : ''}</span>;
};

const Divider = ({ ids, data, basePath }) => (<div ></div>);

const validate = (values) => {
    
    const errors = {};
   
    if (!values.restaurant || !values.restaurant.tcName) {
        errors.restUrlId = ['Rest Url Id is invalid'];
    }

    //Y2K38 issue
    if (new Date(values.startDisplayTimestamp).getYear() >= 138) {
        errors.startDisplayTimestamp = ['The start date should be before 2038.'];
    }

    if (new Date(values.endDisplayTimestamp).getYear() >= 138) {
        errors.endDisplayTimestamp = ['The end date should be before 2038.'];
    }

    return errors
};

export const CustomFolderChip = enhance(({ push, classes, ...props }) =>
    <div>
    {
        props.data && Object.keys(props.data).map((folderId) =>
        <Chip
            key={folderId}
            avatar={
                <Avatar>
                    <FolderIcon />
                </Avatar>
            }
            label={props.data[folderId].folder.tcName}
            onClick={
                () => {
                    let path = `/wechat/folder/${folderId}`;
                    push(path);
                }
            }
        />
        )
    }
    </div>
);

export const WechatRestaurantEdit = enhance(({ push, reset, classes, change, ...props }) => {
    const { folderId: folder_id_string } = parse(props.location.search);
    const folderId = folder_id_string ? folder_id_string : '';
    const toFolderRestUrl = folderId ? `/wechat/folder/${folderId}/restaurant` : '';
    const redirect = folderId ? toFolderRestUrl : editRedirect;

    return <Edit title={<WechatRestaurantEditTitle />} {...props} className={classes.form}>
        <TabbedForm form="wechat-restaurant-edit" destroyOnUnmount toolbar={<EditToolbar />} redirect={redirect} validate={validate} >
            <FormTab label="1. Restaurant">
                <TextField source="restaurant.tcName" label="Name(TC)"/>
                <TextInput source="refFolderCode" disabled={true} formClassName={classes.form_field} />
                <ReferenceManyField formClassName={classes.form_field} addLabel={false} reference="wechat/restaurant" target="folderCode" source="refFolderCode" sort={{ field: 'sequence', order: 'DESC' }}  >
                    <CustomFolderChip />
                </ReferenceManyField>
                <Divider />                
                <TextInput source="restUrlId" validate={required()} label="Rest Url Id" formClassName={classes.form_field} onChange={
                    (event, restUrlId) => {
                        dataProvider(GET_ONE, 'shop/restaurant', { id: restUrlId })
                            .then(response => {
                                change("wechat-restaurant-edit", 'restaurant.tcName', response.data.tcName);
                            })
                            .catch(() => {
                                change("wechat-restaurant-edit", 'restaurant.tcName', "");
                            });  
                    }
                } />
                <FormDataConsumer formClassName={classes.form_field}>
                    {({ formData }) => {
                        return formData.restaurant && formData.restaurant.tcName && <Chip
                        avatar={
                          <Avatar>
                            <RestaurantIcon />
                          </Avatar>
                        }
                        label={formData.restaurant.tcName} />
                    }}
                </FormDataConsumer>
                <TextInput source="restaurant.tcName" style={{ display: 'none'}} label="Restaurant Name" formClassName={classes.form_field} validate={required()} disabled={true} />        
                <Divider />
                <SelectInput source="status" choices={[
                    { id: 'A', name: 'A' },
                    { id: 'D', name: 'D' },
                    { id: 'P', name: 'P' }
                ]} validate={[required(), validateStatus]} />   
                <TextInput source="enShortDescription" label="Incentive Message(EN)" resettable formClassName={classes.form_field} />    
                <TextInput source="tcShortDescription" label="Incentive Message(TC)" resettable formClassName={classes.form_field} />
                <TextInput source="scShortDescription" label="Incentive Message(SC)" resettable formClassName={classes.form_field} />
                <DateTimeInput source="startDisplayTimestamp" label="Start Date" formClassName={classes.form_field} options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />
                <DateTimeInput source="endDisplayTimestamp" label="End Date" formClassName={classes.form_field} options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />
                <NumberInput source="sequence" validate={[required(), number(), minValue(0)]} formClassName={classes.form_field} />
                <BooleanInput source="hasIconImage" label="Show Icon" />
            </FormTab>
            <FormTab label="2. Image" path="image">
                <ReferenceManyField
                    reference="restaurant/media"
                    target="mediaKey"
                    source="restaurant.restUrlId"
                    addLabel={false} >
                    <Datagrid>
                        <ImageField source="mediaCosUrl" />
                        <TextField source="mediaType" />
                        <TextField source="status" />
                        <DateField source="updateTimestamp" showTime />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField >               
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <RestaurantMediaQuickCreateButton {...rest} />
                    }
                </FormDataConsumer>
            </FormTab >           
        </TabbedForm>
    </Edit>
});

const CreateToolbar = (props) => {
    return <Toolbar {...props} >
        <CustomButton
            type="CREATE"
            label="NEXT"
            resourceName="wechat/restaurant"
            redirect={(basePath, id) => {
                return `${basePath}/${id}/image?folderId=${props.record.folderId}&refFolderCode=${props.record.refFolderCode}`
            }}
            needToSubmit={needToSubmit()}
        />
    </Toolbar>
};

export const WechatRestaurantCreate = enhance(({ classes, change, ...props }) => {
    const { refFolderCode: ref_folder_code_string } = parse(props.location.search);
    const refFolderCode = ref_folder_code_string ? ref_folder_code_string : '';
         
    return <Create title="Wechat Restaurant - Create" {...props} className={classes.form}>
        <TabbedForm form="wechat-restaurant-create" destroyOnUnmount toolbar={<CreateToolbar />} defaultValue={{ refFolderCode, status : 'A', hasIconImage : true, startDisplayTimestamp: new Date().getTime(), endDisplayTimestamp: new Date(new Date().setFullYear(new Date().getFullYear() + 10)).getTime() }} validate={validate}>
            <FormTab label="1. Restaurant">
                <TextInput source="refFolderCode" validate={required()} disabled={refFolderCode ? true: false} />
                <TextInput source="restUrlId" validate={required()} label="Rest Url Id" formClassName={classes.form_field} onChange={
                    (event, restUrlId) => {
                        dataProvider(GET_ONE, 'shop/restaurant', { id: restUrlId })
                            .then(response => {
                                change("wechat-restaurant-create", 'restaurant.tcName', response.data.tcName);
                            })
                            .catch(() => {
                                change("wechat-restaurant-create", 'restaurant.tcName', "");
                            });  
                    }
                } />
                <FormDataConsumer formClassName={classes.form_field}>
                    {({ formData }) => {
                        return formData.restaurant && formData.restaurant.tcName && <Chip
                        avatar={
                          <Avatar>
                            <RestaurantIcon />
                          </Avatar>
                        }
                        label={formData.restaurant.tcName} />
                    }}
                </FormDataConsumer>
                <TextInput source="restaurant.tcName" style={{ display: 'none'}} label="Restaurant Name" formClassName={classes.form_field} validate={required()} disabled={true} />
                <SelectInput source="status" choices={[
                    { id: 'A', name: 'A' },
                    { id: 'D', name: 'D' },
                    { id: 'P', name: 'P' }
                ]} validate={[required(), validateStatus]} />            
                <TextInput source="enShortDescription" label="Incentive Message(EN)" resettable formClassName={classes.form_field} />           
                <TextInput source="tcShortDescription" label="Incentive Message(TC)" resettable formClassName={classes.form_field} />
                <TextInput source="scShortDescription" label="Incentive Message(SC)" resettable formClassName={classes.form_field} />
                <DateTimeInput source="startDisplayTimestamp" label="Start Date" formClassName={classes.form_field} options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />
                <DateTimeInput source="endDisplayTimestamp" label="End Date" formClassName={classes.form_field} options={{ format: 'yyyy-MM-dd HH:mm', ampm: false }} parse={dateParser} validate={required()} />         
                <NumberInput source="sequence" validate={[required(), number(), minValue(0)]} formClassName={classes.form_field} />
                <BooleanInput source="hasIconImage" label="Show Icon" />
            </FormTab>
            <FormTab label="2. Image" path="image" disabled={true}>
                <ReferenceManyField
                    reference="restaurant/media"
                    target="mediaKey"
                    source="restaurant.restUrlId"
                    addLabel={false} >
                    <Datagrid>
                        <ImageField source="mediaCosUrl" />
                        <TextField source="mediaType" />
                        <TextField source="status" />
                        <DateField source="updateTimestamp" showTime />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField >            
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <RestaurantMediaQuickCreateButton {...rest} />
                    }
                </FormDataConsumer>
            </FormTab >            
        </TabbedForm>
    </Create>
});