import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Slide } from 'react-slideshow-image';
import uniqueId from 'lodash/uniqueId';
import Image from './Image';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import '../styles/SlideshowPreviewButton.scss';

class SlideshowPreviewButton extends Component {
    state = {
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    render() {
        const { slideList, duration, translate } = this.props;
        const { showDialog } = this.state;

        return (
            <Fragment>
                <Button variant="contained" color="primary" onClick={this.handleClick} fullWidth>{translate('pos.preview')}</Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Slideshow Preview"
                    className="slideshow-preview-dialog"
                >
                    <DialogTitle>{translate('pos.preview')}</DialogTitle>
                    <DialogContent>
                        <Slide
                            duration={duration}
                            transitionDuration={500}
                            arrows={false}
                        >
                        {slideList.map(slide => (
                            <div className="each-slide" key={uniqueId()}>
                                {slide.slideshowMedia.mediaType === "IMAGE" &&
                                <Image
                                    className="image" 
                                    src={slide.slideshowMedia.mediaPath} 
                                    fallbackSrc={"/images/image-not-found.jpg"} />
                                }
                                {slide.slideshowMedia.mediaType === "VIDEO" &&
                                <div className="movie-wrapper">
                                    <i className="material-icons movie">movie</i>
                                </div>
                                }
                            </div>
                        ))}
                        </Slide>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={this.handleCloseClick}>
                            {translate('pos.dialog.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

SlideshowPreviewButton.propTypes = {
    slideList: PropTypes.array,
    duration: PropTypes.number
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

const enhance = compose(
    connect(
		mapStateToProps,
		mapDispatchToProps
	),
    translate
  );
  
  export default enhance(SlideshowPreviewButton);