import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import '../styles/confirmationDialog.scss';

class ConfirmationDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.isOpen}                
                >
                <div className="confirmation-dialog">
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent
                        >
                        <DialogContentText id="alert-dialog-description">
                            {this.props.content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {this.props.handleCancel && 
                        <Button
                            onClick={(event) => {
                                event.stopPropagation();
                                this.props.handleCancel();
                            }}
                            color="primary">
                            {this.props.cancelText}
                        </Button>
                        }
                        {this.props.handleConfirm && 
                        <Button
                            onClick={(event) => {
                                event.stopPropagation();      
                                this.props.handleConfirm();
                            }}
                            color="primary">
                            {this.props.confirmText}
                        </Button>
                        }
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

ConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.string,    
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    handleCancel: PropTypes.func,
    handleConfirm: PropTypes.func,
};

export default ConfirmationDialog;