import React, { Component } from 'react';
import { translate } from 'react-admin';
import Select from 'react-select';
import PropTypes from 'prop-types';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: data.hasSlideshow ? '#0052cc' : null,
        };
    },
    input: styles => (styles),
    placeholder: styles => (styles),
    singleValue: (styles, { data }) => (styles),
};


class RestaurantAutoComplete extends Component {
    

    render() {
        const { selectedOption, handleRestChange, restaurantList, translate } = this.props;
        let options = restaurantList && restaurantList.map(val => (
            { value: (val.restaurant) ? val.restaurant.restUrlId : null, label: val.restaurant.hasSlideshow ? translate('pos.auto_complete.restaurant.customized') + val.restaurant.tcName : (val.restaurant) ? val.restaurant.tcName : null, hasSlideshow : val.restaurant.hasSlideshow }
        ));

        return (
            <Select
                isClearable
                placeholder={translate('pos.auto_complete.restaurant.placeholder')}
                value={selectedOption}
                onChange={handleRestChange}
                options={options}
                styles={colourStyles}
            />
        )
    }
}

RestaurantAutoComplete.propTypes = {
    brandId: PropTypes.string,
    handleRestChange: PropTypes.func,
    selectedOption: PropTypes.object,
    restaurantList: PropTypes.array,
};

export default translate(RestaurantAutoComplete);