import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

const folderLogoStyle = {
    avatar: {
        height: 35,
        width: 35,
    },
};

const FolderLogo = withStyles(folderLogoStyle)(
    ({ classes, record }) => {
        return <Avatar
            className={classes.avatar}
            src={record.imageUrl}
        />
    }
);

export default FolderLogo;