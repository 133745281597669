import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import SlideshowPreviewButton from './SlideshowPreviewButton';
import Image from './Image';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import '../styles/slideshowPreview.scss';

//TODO: move to scss
const styles = theme => ({
  previewListWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    border: '1px solid LightGray',
    'border-radius': 5,
    padding: 8
  },
  previewList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  previewHeader: {
    display: 'flex',
    'align-items': 'flex-start',
    'justify-content': 'space-between',
    'margin-bottom': 8
  },
  previewTitle: {
  },
  ratioWrapper: {    
    'margin-top': '-10px'
  },
  ratioGulu: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'space-between',
  },
  ratioRest: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'space-between'
  },
  ratioLabel: {
    "margin-right": 5
  },
  interval: {
    'max-width': 60
  },
  ratio: {
    'max-width': 60
  },
});

class SlideshowPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      spacing: '8',
    };
  }

  genList = (mediaRatioA, mediaRatioB, mediaListA, mediaListB) => {
    // console.log("genList ", mediaRatioA, mediaRatioB, mediaListA, mediaListB);
    let slideList = [];
  
    let mediaListALength = 0;
    let mediaListBLength = 0;
    let ratioA = mediaRatioA ? Number.parseInt(mediaRatioA) : 0;
    let ratioB = mediaRatioB ? Number.parseInt(mediaRatioB) : 0;
  
    if (mediaListA.length > 0) {
      mediaListALength = mediaListA.length;
      mediaListALength = ( (ratioA % mediaListALength) === 0) ? 1 : mediaListALength;
    }
  
    if (mediaListB.length > 0) {
      mediaListBLength = mediaListB.length;
      mediaListBLength = ( (ratioB % mediaListBLength ) === 0) ? 1 : mediaListBLength;
    }
  
    if(mediaListALength === 0 && mediaListBLength > 0) {
      slideList = mediaListB;
    } else if(mediaListBLength === 0 && mediaListALength > 0) {
      slideList = mediaListA;      
    } else {
      let total = (mediaListALength * mediaListBLength) * (ratioA + ratioB);

      // console.log("total", total);

      if(total >= 100) {
        total = 100;
      }
      let indexA = 0;
      let indexB = 0;
      
      while (0 < total) {
        if (mediaListA.length > 0) {
          for (var b = 0; b < ratioA; b++) {
            if (indexA >= mediaListA.length) {
              indexA = 0;
            }
            
            slideList.push(mediaListA[indexA++]);
          }
        }
  
        total -= ratioA;
  
        if (mediaListB.length > 0) {
          for (var g = 0; g < ratioB; g++) {
            if (indexB >= mediaListB.length) {
              indexB = 0;
            }

            slideList.push(mediaListB[indexB++]);
          }
        }
  
        total -= ratioB;
      }
    }

    return slideList;
  }

  gcd_two_numbers = (x, y) => {
    if ((typeof x !== 'number') || (typeof y !== 'number')) 
      return false;
    x = Math.abs(x);
    y = Math.abs(y);
    while(y) {
      var t = y;
      y = x % y;
      x = t;
    }
    return x;
  }
  

  render() {
    const { classes, brandTemplate, theGuluTemplate, advTemplate, slideshow, translate } = this.props;

    let generalSlideList = this.genList(slideshow.ratioRest, slideshow.ratioGulu, brandTemplate.slideshowTemplateMediaMapList, theGuluTemplate.slideshowTemplateMediaMapList);
    let slideList = [];

    // console.log("advTemplate", advTemplate);

    if(advTemplate) {
      let sovRatio = slideshow.sov;
      let generalRatio = 100 - sovRatio;
      // let gcd = this.gcd_two_numbers (sovRatio, generalRatio);

      // console.log("sovRatio", sovRatio);
      // console.log("gcd", gcd);

      slideList = this.genList(sovRatio / 10, generalRatio / 10, advTemplate.slideshowTemplateMediaMapList, generalSlideList);
    } else {
      slideList = generalSlideList;
    }

    // console.log("slideshow", slideshow);
    // console.log("slideList", slideList);
    return (
      <div className="slideshow-preview">
        <div className={classes.previewHeader}>
          <div className={classes.previewTitle}>
          <Typography variant="headline" component="h5" >{translate('pos.preview')}</Typography>
          </div>
          {
          this.props.permissions === 'ROLE_SYSTEM_ADMIN' &&
          <div className={classes.intervalWrapper}>
              <div>{translate('pos.interval')}</div>
              <div>
                <input
                  maxLength="6"
                  value={slideshow.interval ? slideshow.interval : ''}
                  onChange={this.props.handlePreviewChange('interval')}
                  className={classes.interval}
                />
                {
                  this.props.errorMap && this.props.errorMap.interval &&
                  <div className="error">{this.props.errorMap.interval}</div>
                }
              </div>
          </div>
          }
          {
          this.props.permissions === 'ROLE_SYSTEM_ADMIN' &&
          <fieldset className={classes.ratioWrapper}>
              <legend>{translate('pos.ratio')}</legend>              
              <div className={classes.ratioRest}>              
                <label className={classes.ratioLabel}>{translate('pos.restaurant')}:</label>
                <input
                  maxLength="1"
                  value={slideshow.ratioRest}
                  onChange={this.props.handlePreviewChange('ratioRest')}
                  className={classes.ratio}
                />
              </div>
              <div className={classes.ratioGulu}>
                <label className={classes.ratioLabel}>{translate('pos.the_gulu')}:</label>
                <input
                  maxLength="1"
                  value={slideshow.ratioGulu}
                  onChange={this.props.handlePreviewChange('ratioGulu')}
                  className={classes.ratio}
                />
              </div>              
              {
                this.props.errorMap && this.props.errorMap.ratioRest &&
                <div className="error">{this.props.errorMap.ratioRest}</div>
              }
              {
                this.props.errorMap && this.props.errorMap.ratioGulu &&
                <div className="error">{this.props.errorMap.ratioGulu}</div>
              }
          </fieldset>
          }
          <div className={classes.previewBtnWrapper}>
            <SlideshowPreviewButton 
              slideList={slideList}
              duration={slideshow.interval}
            />
          </div>
        </div>

        <div className={classes.previewListWrapper}>
          {
            slideList &&
            <GridList className={classes.previewList} cols={4}>
              {slideList.map(slide => (
                <GridListTile key={uniqueId()}>
                  {slide.slideshowMedia.mediaType === "IMAGE" &&
                    <div className="movie-wrapper">
                      <Image
                        className="image" 
                        src={slide.slideshowMedia.mediaPath} 
                        fallbackSrc={"/images/image-not-found.jpg"} />
                    </div>
                  }
                  {slide.slideshowMedia.mediaType === "VIDEO" &&
                    <div className="movie-wrapper">
                      <i className="material-icons movie">movie</i>
                    </div>
                  }
                </GridListTile>
              ))}
            </GridList>
          }
        </div>
      </div>
    );
  }
}

SlideshowPreview.propTypes = {
  slideshow: PropTypes.object,
  brandTemplate: PropTypes.object,
  theGuluTemplate: PropTypes.object,
  advTemplate: PropTypes.object,
  handlePreviewChange: PropTypes.func,
  errorMap: PropTypes.object,
  permissions: PropTypes.string,
}

const enhance = compose(
  withStyles(styles),
  translate
);

export default enhance(SlideshowPreview);